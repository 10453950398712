/* Container principale */
.avissi-container {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 0 15px;
  }
  
  /* New Blue Header */
  .blue-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #002060;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .avissi-count {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ff4b4b;
    margin-right: 15px;
  }
  
  .avissi-title-text {
    font-size: 2rem;
    font-weight: bold;
    color: #ffffff;
    flex: 1;
  }
  
  .avissi-search {
    width: 250px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
  }
  
  .avissi-search::placeholder {
    color: #555;
  }
  
  /* Content Layout */
  .avissi-content {
    display: flex;
    gap: 20px;
  }
  
  /* Sidebar */
  .avissi-sidebar {
    width: 250px;
    min-width: 200px;
    background-color: #f3f4f6;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  .sidebar-list {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar-list li {
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    color: #333;
    border-radius: 4px;
    transition: 0.2s ease;
  }
  
  .sidebar-list li:hover {
    background-color: #e2e6ea;
  }
  
  .sidebar-list li.active {
    background-color: #007bff;
    color: #fff;
  }
  
  /* Main Content */
  .avissi-main-content {
    flex: 1;
  }
  
  .avissi-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .avissi-card {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  /* Closed tag */
  .closed-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #dc3545;
    color: #fff;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    text-transform: uppercase;
    z-index: 1;
  }
  
  .avissi-separator {
    border-top: 1px solid #ddd;
    margin: 10px 0;
  }
  
  /* Image with increased size */
  .avissi-image {
    text-align: center;
    margin: 10px 0;
  }
  
  .avissi-image-fixed {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  /* Text */
  .avissi-card-title {
    font-size: 1.4rem;
    color: #007bff;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .avissi-short-description,
  .avissi-descrizione,
  .avissi-soggetto,
  .avissi-link-istituzionale,
  .avissi-criteria {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #333;
  }
  
  .avissi-link-istituzionale a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
  }
  
  .avissi-link-istituzionale a:hover {
    text-decoration: underline;
  }
  
  /* Dates */
  .avissi-dates {
    margin-top: auto;
  }
  
  .avissi-date {
    font-size: 0.95rem;
    color: #555;
  }
  
  .date-closed {
    color: red;
    font-weight: bold;
  }
  
  /* Error Message */
  .avissi-error {
    text-align: center;
    color: #dc3545;
    font-size: 1rem;
    margin-top: 20px;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .avissi-content {
      flex-direction: column;
    }
  
    .avissi-image-fixed {
      width: 100%;
      height: auto;
    }
  }


  /* General Container */
.formazione-container {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
  }
  
  /* Header Section */
  .formazione-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .formazione-title {
    font-size: 2.5rem;
    color: #002060;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .formazione-description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Layout */
  .formazione-layout {
    display: flex;
    margin-top: 20px;
  }
  
  /* Vertical Menu */
  .formazione-menu {
    width: 20%;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
    padding: 15px;
  }
  
  .formazione-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .formazione-menu li {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 5px;
    text-align: center;
    color: #002060;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .formazione-menu li:hover {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .formazione-menu li.active {
    background-color: #007bff;
    color: #ffffff;
    font-weight: bold;
  }
  
  /* Content Section */
  .formazione-content {
    width: 75%;
  }
  
  /* Grid Layout */
  .formazione-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  /* Training Card */
  .training-card {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .training-card:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  /* Training Image */
  .training-image {
    width: 100%;
    max-height: 150px; /* Reduce image height for better balance */
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  /* Training Details */
  .training-details {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 15px;
  }
  
  .training-title {
    font-size: 1.2rem;
    color: #007bff;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .training-description {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  /* Button */
  .training-button {
    display: inline-block;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .training-button:hover {
    background-color: #0056b3;
  }
  
  /* Error Message */
  .formazione-error {
    font-size: 1rem;
    color: #dc3545;
    margin: 20px 0;
    text-align: center;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .formazione-layout {
      flex-direction: column;
    }
  
    .formazione-menu {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .formazione-content {
      width: 100%;
    }
  
    .formazione-title {
      font-size: 2rem;
    }
  
    .formazione-description {
      font-size: 1rem;
    }
  
    .formazione-grid {
      grid-template-columns: 1fr;
    }
  }
  
  /* Training Details */
.training-details {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 15px;
  }
  
  .training-details p {
    margin: 5px 0;
  }
  
  .discount {
    color: #dc3545;
    font-weight: bold;
  }
  
  .training-title {
    font-size: 1.2rem;
    color: #007bff;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .training-description {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .training-button {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .training-button:hover {
    background-color: #0056b3;
  }
  
  /* Error Message */
  .formazione-error {
    font-size: 1rem;
    color: #dc3545;
    margin: 20px 0;
    text-align: center;
  }
  