/* General container styles */
.stripe-card-container,
.card-element-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
}

/* Stripe's CardElement */
.StripeElement {
  width: 100%; /* Ensure it spans the container */
  padding: 16px; /* Adjust padding for better usability */
  background-color: white;
  border: 3px solid #e9eaea; /* Slightly thicker border */
  border-radius: 8px; /* Increase border-radius for rounded corners */
  font-size: 18px; /* Larger font size */
  box-sizing: border-box; /* Include padding and border in the width */
  margin-bottom: 20px; /* Add space below */
}

/* CardElement container */
.card-element-container {
  max-width: 600px; /* Maintain a consistent layout */
  margin: 16px auto; /* Center the container */
}


/* Quantity selector styles */
.quantity-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.decrement-button,
.increment-button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  width: 100px;
}

.quantity-value {
  min-width: 20px;
  text-align: center;
}

/* Total price styles */
.total-price {
  text-align: center;
  font-size: 18px;
  margin: 10px 0;
}

/* Payment button styles */
.payment-button-checkout {
  background: #5469d4;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  margin: 16px auto;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
}

.payment-button-checkout:hover {
  background: #4358a3;
  transform: translateY(-2px);
}

.payment-button-checkout:active {
  transform: translateY(0);
}

.payment-button-checkout:disabled {
  background: #c3c4c7;
  cursor: not-allowed;
  opacity: 0.8;
}

/* Spinner styles */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0 auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0 10.2px;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive design */
@media only screen and (max-width: 600px) {
  .form-checkout,
  .stripe-card-container {
    width: 90%;
    padding: 0 16px;
  }

  .payment-button-checkout {
    width: 100%;
    margin-top: 10px;
  }

  .StripeElement {
    width: 100%;
    padding: 8px;
  }
}

@media only screen and (min-width: 601px) {
  .form-checkout {
    width: 70vw;
  }

  .box-pay {
    margin: 0 27%;
    text-align: center;
  }

  .StripeElement {
    padding: 8px 12px;
    border-radius: 5px;
    border: 2px solid #e9eaea;
    width: 100%;
  }
}


/* Container for the form fields */
.box-pay {
  margin: 0 auto;
  text-align: left;
  width: 100%; /* Fill available space */
  max-width: 600px; /* Limit width for larger screens */
  padding: 20px;
}

/* Input fields for email and name */
.email-address-name {
  width: 100%; /* Make input fields fill their container */
  max-width: 600px; /* Limit width for consistency */
  padding: 12px; /* Add spacing inside the input */
  font-size: 16px;
  border: 2px solid #e9eaea; /* Match other elements' border */
  border-radius: 5px;
  margin: 10px 0; /* Add space between fields */
  box-sizing: border-box; /* Include padding and border in the width */
}

/* Stripe CardElement container */
.card-element-container {
  width: 100%; /* Fill the container */
  margin: 16px 0; /* Add space above and below */
}

/* Style for Stripe's CardElement */
.StripeElement {
  width: 100%; /* Ensure it spans the container */
  padding: 12px;
  background-color: white;
  border: 2px solid #e9eaea;
  border-radius: 5px;
  box-sizing: border-box; /* Include padding and border in the width */
  margin-bottom: 16px;
}

/* Payment button */
.payment-button-checkout {
  background: #5469d4;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%; /* Full width */
  max-width: 300px; /* Limit button width */
  margin: 16px auto;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
}

/* Responsive design for small screens */
@media only screen and (max-width: 600px) {
  .box-pay {
    width: 90%;
    padding: 16px;
  }

  .email-address-name,
  .StripeElement {
    width: 100%; /* Fill width on small screens */
  }
}
