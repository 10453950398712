body {
    font-family: sans-serif;
}
 
 @media (min-width: 768px) {
  .sloganContainer{
    margin-bottom: 10px;
    margin-top: 100px;
  }
    .input-container {
        display: flex;
        position: relative;
        width: calc(80% - 20px); 
        margin: 0 auto; 
        margin-bottom: 40px;
        padding-left: 3px;
      }
      .submit-button {
        border-radius: 0 3px 3px 0;
        background-color: white;
        color: black;
        border: none; 
        cursor: pointer;
      }
      
      .chatbox-style {
        text-align: left;
        display: block;
        margin: 0 auto; 
        width: calc(100% - 20px); 
        padding: 10px; 
        text-align: left; 
        box-sizing: border-box; 
        min-height: 5vw;
        overflow-y: hidden;
        font-size: 16px;
      }
      
      .chatbox-style:focus {
        outline: none;  
      }  
      .title-input-style{
        width: 20%;
        height: 2vw;
        font-size: 16px;
        padding-left: 5px;
        box-shadow:#3498db;
        border-radius: 5px;
        border: 2px solid #285075;
      }
      .newconvo-title{
        font-size: 45px; 
        padding-left: 50px;
        padding-bottom: 10px;
    }
    .newconvo-landing {
      font-Size: 15px;
      font-family: sans-serif;
      text-align: center;
      color: #092f7a;
      background: blue;
      margin: 0 auto; /* Center the container */
      padding: 20px; /* Optional: Add padding for spacing inside the box */
      width: 100%; /* Ensure it takes full width up to max-width */
      box-shadow: 4px 2px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
      border-radius: 5px; /* Optional: Rounded corners */
    }
    
    .stage-indicator {
      display: flex;
      justify-content: center;
      gap: 20px;
      background-color: white;
    }
    
    .stage-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
    }
    
    .circle {
      width: 100px;
      height: 50px;
      border-radius: 10%; /* Makes the div circular */
      border: 2px solid #ccc;
      display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
        font-size: 1.2em;
        position: relative;
        z-index: 1;
    }
    .label {
      margin-top: 5px; /* Space between the circle and the text */
      font-size: 24px;
      color: white;
    }

    .threadwords {
      border: 1px solid grey;
      border-radius: 5px;
      font-size: 24px;
      padding: 10px;
      color: #092f7a;
      box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
      line-height: 2; /* Higher line spacing */
    }
    
    .boxes {
    
      bottom: 100px;
      left: 80%;
      transform: translateX(-50%);
      color: white;
      font-size: 26px;
      cursor: pointer;
      background: #FF5758;
      border-radius: 5px;
      border: 2px solid white;
      padding: 5px 15px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      max-width: 12rem;
    }
    
    .box-container {
      display: flex; /* Use flexbox to arrange boxes */
      justify-content: center; /* Center boxes horizontally */
      gap: 20px; /* Space between boxes */
      margin-top: 25px;
    }
    .box-container-landing {
      display: flex; /* Use flexbox to arrange boxes */
      justify-content: left; /* Center boxes horizontally */
      gap: 20px; /* Space between boxes */
      max-width: 400px;
    }
    .boxes {
      background: #FF5758; /* Your box color */
      color: white; /* Text color */
      border-radius: 5px;
      border: 2px solid white;
      padding: 5px 15px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      text-align: center;
      height: 4rem;
      max-width: 12rem; /* Optional max width */
      display: flex; /* Use flexbox for box content */
      align-items: center; /* Center items vertically */
      justify-content: center; /* Center items horizontally */
      font-size: 25px;
      margin-right: 50px;
    }
    .aiutare-fp-container {
      justify-content: space-around; 
    }
    .help-item {
      flex: 0 1 48%; /* Adjusts width to show 2 items per row */
    }
    .title-fonts { /* Styling for titles */
      font-size: 32px; /* Larger, bold text for the title */
      font-weight: bold;
      margin-bottom: 5px; /* Space between the title and the description */
    }
    
    .description-fonts { /* Styling for descriptions */
      font-size: 24px; /* Smaller, lighter text for the description */
      font-weight: normal;
      text-align: center;
    }
    .threadunic-container-wb {
      padding: 10px;         /* Optional: adds space inside the container */
      background-color: #f4f4f4;
      color: #092f7a; 
      border-radius: 10px;
      box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
      border-radius: 5px; /* Optional: Rounded corners */
      display: flex;
      letter-spacing: 2px;
    }
  }
  
  

  @media (max-width: 768px) {
    .threadunic-container-wb {
      padding: 10px;         /* Optional: adds space inside the container */
      background-color: #f4f4f4;
      color: #092f7a; 
      border-radius: 10px;
      box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
      border-radius: 5px; /* Optional: Rounded corners */
      display: block;
      letter-spacing: 2px;
    }
    .input-container {
        display: flex;
        position: relative;
        width: calc(95% - 20px); 
        margin: 0 auto; 
        margin-bottom: 40px;
        margin-left: 2px;
      }
      .submit-button {
        border-radius: 0 3px 3px 0;
        background-color: white;
        color: black;
        border: none; 
        cursor: pointer;
        font-size: 18px;
      }
      
      .chatbox-style {
        text-align: left;
        display: block;
        margin: 0 auto; 
        width: calc(100% - 20px); 
        padding: 10px; 
        text-align: left; 
        box-sizing: border-box; 
        min-height: 20vw;
        overflow-y: hidden;
        font-size: 14px;
      }
      
      .chatbox-style:focus {
        outline: none;  
      }  
      .title-input-style{
        width: 250px;
        height: 8vw;
        font-size: 16px;
        padding-left: 5px;
        box-shadow:#3498db;
        border: 2px solid #285075;
        border-radius: 5px;
      }
      .newconvo-title{
          font-size: 25px; 
          padding-left: 5px;
          padding-right: 10px;
      }
      
    .newconvo-landing {
      font-size: 15px; 
      font-family: sans-serif;
      text-align: center;
      color: #092f7a;
      background: white;
      margin: 0 auto; /* Center the container */
      padding: 20px; /* Optional: Add padding for spacing inside the box */
      max-width: 300px; /* Set a maximum width for the box */
      width: 100%; /* Ensure it takes full width up to max-width */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
      border-radius: 5px; /* Optional: Rounded corners */
    }
      .old-title{
        font-size: 25px; 
        height: auto;
        padding-left: 5px;
        padding-right: 10px;
    }
      .stage-indicator {
        display: flex;
        justify-content: center;
        gap: 20px;
        background-color: white;
      }
       
      .stage-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .circle {
        width: 40px;
        height: 30px;
        border-radius: 10%; /* Makes the div circular */
        border: 2px solid #ccc;
        display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
          font-size: 1em;
          position: relative;
          z-index: 1;
      }
      .label {
        margin-top: 5px; /* Space between the circle and the text */
        font-size: 18px;
        color: white;
      }

      .threadwords {
        border: 1px solid grey;
        border-radius: 5px;
        font-size: 18px;
        padding: 10px;
        color: #092f7a;
        box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
        line-height: 2; /* Higher line spacing */
    }
    .box-container {
      display: flex; /* Use flexbox to arrange boxes */
      justify-content: center; /* Center boxes horizontally */
      gap: 20px; /* Space between boxes */
      margin-top: 25px;
    }
    .box-container-landing {
      display: flex; /* Use flexbox to arrange boxes */
      justify-content: left; /* Center boxes horizontally */
      gap: 20px; /* Space between boxes */
    }
    .boxes {
      background: #FF5758; /* Your box color */
      color: white; /* Text color */
      border-radius: 5px;
      border: 2px solid white;
      padding: 5px 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      text-align: center;
      height: 2rem;
      max-width: 12rem; /* Optional max width */
      display: flex; /* Use flexbox for box content */
      align-items: center; /* Center items vertically */
      justify-content: center; /* Center items horizontally */
      font-size: 15px;
      margin-right: 5px;
    }

    .help-item {
      flex: 0 1 100%; /* Full width on mobile */
    }
    .aiutare-fp-container {
      justify-content: center;
    }
    .title-fonts { /* Styling for titles */
      font-size: 26px; /* Larger, bold text for the title */
      font-weight: bold;
      margin-bottom: 5px; /* Space between the title and the description */
    }
    
    .description-fonts { /* Styling for descriptions */
      font-size: 18px; /* Smaller, lighter text for the description */
      font-weight: normal;
      text-align: center;
    }
  }

  .loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  td {
  padding: 8px 20px; /* Adjust the values as needed */
}

.table-frontpage{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;

}

.newconvo-title{
  align-items: left;
  gap: 10px;
    color: white;
    display: 'flex';
    justify-content:start;
    align-items: center;
    background: white;
}



.active {
  background-color: #092f7a; /* Light green for active stage */
}

.tick {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
}

.completed {
  background-color: #39bc39; /* Green for completed stages */
}

.active {
  background-color: white; /* Green for the active stage */
}

.pending {
  background-color: #d3d3d3; /* Grey for pending stages */
}

.tick-completed {
  color: rgb(250, 250, 250); /* Green tick for completed stages */
  font-size: 20px;
}

.tick-pending {
  color: grey; /* Grey tick for pending and active stages */
  font-size: 20px;
}
.threadunic-container {
  padding: 10px;         /* Optional: adds space inside the container */
  background-color: #f4f4f4;
  color: white; 
  border-radius: 10px;
  box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
  border-radius: 5px; /* Optional: Rounded corners */
}
.postnote-container {
  padding: 10px;         /* Optional: adds space inside the container */
  background-color: #f4f4f4;
  color: #092f7a; 
  border-radius: 10px;
  box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
  border-radius: 5px; /* Optional: Rounded corners */
}
.preapply-container {
  padding: 10px;         /* Optional: adds space inside the container */
  background-color: #f4f4f4;
  color: black; 
  border-radius: 10px;
  box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
  border-radius: 5px; /* Optional: Rounded corners */
}



.threadunic-container-lb {
  max-width: 50px;     /* Maximum width of the container */
  margin: 0 auto;        /* Top and bottom margin 0, left and right auto */
  padding: 20px;         /* Optional: adds space inside the container */
  background-color: #092f7a;
  color: white; 
  border: 1px solid white;
  border-radius: 10px;
}


.searchy-container {
  margin: 0 auto;        /* Top and bottom margin 0, left and right auto */
  background-color: white;
  color: white; 
}

.searchy-containered {
  margin: 0 auto;        /* Top and bottom margin 0, left and right auto */
  padding: 2px;         /* Optional: adds space inside the container */
  background-color: rgb(241, 232, 232);
  color: black; 
      width: 100%; /* Ensure it takes full width up to max-width */
      box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
      border-radius: 5px; /* Optional: Rounded corners */
}

.searchy-container-landing {
  margin: 0 auto;        /* Top and bottom margin 0, left and right auto */
  background-color: white;
  color: black; 
}

.searchy-container-comm {
  margin: 0 auto;        /* Top and bottom margin 0, left and right auto */
  padding: 20px;         /* Optional: adds space inside the container */
  background-color: black;
  color: white; 
}

.incentivy-container {
  width: 100%;           /* Use 100% of the viewport width */
  margin: 0 auto; 
  padding: 20px;         /* Optional: adds space inside the container */
  background-color: white;
  color: black; 
  border: 1px solid white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
  border-radius: 5px;
}

.incentivy-container2 {
  width: 100%;           /* Use 100% of the viewport width */
  margin: 0 auto; 
  max-width: 600px;       /* Top and bottom margin 0, left and right auto */
  padding: 20px;         /* Optional: adds space inside the container */
  background-color: #092f7a;
  color: black; 
  border: 1px solid white;
  padding: 10px;
  border-radius: 10px;
    background: #092f7a;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
}

@media (max-width: 768px) {
.incentivy-container-comm {
  width: 100%;           /* Use 100% of the viewport width */
  margin: 0 auto; 
  max-width: 900px;       /* Top and bottom margin 0, left and right auto */
  padding: 20px;         /* Optional: adds space inside the container */
  background-color: black;
  color: white; 
  border: 1px solid white;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 8px;
  margin-top: 20px;
}
.incentivy-container-commer {
  width: 100%;           /* Use 100% of the viewport width */
  margin: 0 auto; 
  max-width: 900px;       /* Top and bottom margin 0, left and right auto */
  padding: 20px;         /* Optional: adds space inside the container */
  background-color: #092f7a;
  color: white; 
  border: 1px solid white;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
}
.piva-container-comm {
  width: 100%;           /* Use 100% of the viewport width */
  margin: 0 auto; 
  max-width: 900px;       /* Top and bottom margin 0, left and right auto */
  padding: 20px;         /* Optional: adds space inside the container */
  background-color: black;
  color: white; 
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 12px;
}
.incentivy-container-comm2 {
  width: 100%;           /* Use 100% of the viewport width */
  margin: 0 auto; 
  max-width: 600px;       /* Top and bottom margin 0, left and right auto */
  padding: 20px;         /* Optional: adds space inside the container */
  background-color: black;
  color: white; 
  padding: 10px;
  text-align: center;
  font-size:25px;
  font-weight: bold;
}
}

@media (min-width: 769px) {
  .incentivy-container-comm {
    width: 100%;           /* Use 100% of the viewport width */
    margin: 0 auto; 
    padding: 20px;         /* Optional: adds space inside the container */
    background-color: black;
    color: white; 
    border: 1px solid white;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
  }
  .piva-container-comm {
    width: 100%;           /* Use 100% of the viewport width */
    margin: 0 auto; 
    max-width: 600px;       /* Top and bottom margin 0, left and right auto */
    padding: 20px;         /* Optional: adds space inside the container */
    background-color: black;
    color: white; 
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
  }
  .incentivy-container-comm2 {
    width: 100%;           /* Use 100% of the viewport width */
    margin: 0 auto; 
    max-width: 600px;       /* Top and bottom margin 0, left and right auto */
    padding: 20px;         /* Optional: adds space inside the container */
    background-color: black;
    color: white; 
    padding: 10px;
    text-align: center;
    font-size:45px;
    font-weight: bold;
  }
  }

.incentivy-container-comm3 {
  width: 100%;           /* Use 100% of the viewport width */
  margin: 0 auto; 
  padding: 20px;         /* Optional: adds space inside the container */
  background-color: #092f7a;
  color: black; 
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .incentivy-container-comm3 ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust spacing between items */
    justify-content: start; /* Default alignment for items */
    padding: 0;
  }

  .incentivy-container-comm3 li {
    flex: 1 1 calc(33.333% - 20px); /* Default: Make each item take up 1/3 of the row */
    max-width: calc(33.333% - 20px); /* Prevent items from stretching */
    box-sizing: border-box; /* Include padding and borders in width */
  }

  /* If there's only one item, center it */
  .incentivy-container-comm3 ul.single-item {
    justify-content: center; /* Center single item */
  }

  .incentivy-container-comm3 ul.double-item > li {
    flex: 1 1 calc(50% - 20px); /* Make each item take up half of the row */
    max-width: calc(50% - 20px); /* Prevent stretching for two items */
  }
}




.threadwords ul {
  list-style-type: none; /* Removes default bullets */
  padding-left: 0; /* Removes default padding */
  margin-left: 10px; /* Ensures there's no default margin */
}

.threadwords li {
  position: relative; /* Needed for absolute positioning of the symbol */
  padding-left: 20px; /* Space for the custom symbol and text padding */
  background-color: #092f7a;
  color: white; 
}

.threadwords li::before {
  content: "\2016"; /* Your custom symbol */
  position: absolute;
  left: 0; /* Align symbol to the left */
  background-color: #092f7a;
  color: white; 
  top: 0; /* Align symbol with the top of the line */
  padding-right: 10px; /* Optional: adds a little space between the symbol and the text */
}

.threadunic-button{
    cursor: pointer;
    font-size: 28px; /* Increased font size to 28px as per requirement */
    color: white; /* Text color */
    background-color: white; /* Button background color */
    border: 1px solid white; /* Remove default border */
    padding: 10px 20px; /* Padding around text */
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center alignment of the text/icon vertically */
    justify-content: center; /* Center alignment of the text/icon horizontally */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow effect */
    margin: auto; /* Margin auto for horizontal centering */
    width: fit-content; /* Set width to fit the content */
    border-radius: "10px";
}

.other-button{
  cursor: pointer;
  font-size: 28px; /* Increased font size to 28px as per requirement */
  color: white; /* Text color */
  background-color: #FF5758; /* Button background color */
  border: 1px solid white; /* Remove default border */
  padding: 10px 20px; /* Padding around text */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center alignment of the text/icon vertically */
  justify-content: center; /* Center alignment of the text/icon horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow effect */
  margin: auto; /* Margin auto for horizontal centering */
  width: fit-content; /* Set width to fit the content */
  border-radius: 10px;
  margin-top: 30px;
}

.incentivethread-details{
  margin: 2px;
  background-color: white;
  color: #092f7a;
  border: 1px solid rgb(252, 251, 251);
  border-radius: 5px;
  box-shadow: 1px 4px 10px rgba(253, 252, 252, 0.5); /* Optional: Add shadow for depth */
  border-radius: 5px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-family: 'Poppins', sans-serif;
}

.incentivethread-details-exp{
  margin: 2px;
  background-color: white;
  padding: 5px 5px 5px 5px;
  color: #092f7a;
  border-left: 14px solid #FF5758;
  border-radius: 5px;
  border-radius: 5px;
}

.captionStyles strong {
  font-weight: bold;  /* Ensures the text is bold */
  white-space: nowrap;  /* Prevents the text from wrapping */
}

.image-fp-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: sans-serif;
  background: #FF5758;
  color: white;
}
.aiutare-fp-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 10px;
  background: white;
  color: #092f7a;
}

.help-item {
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  border-radius: 20px;
  margin: 10px;
  background: #092f7a;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.image-item {
  width: 25%; /* 4 items per row */
  text-align: center;
  display: flex; /* Use flexbox for vertical alignment */
  flex-direction: column; /* Stack image and text vertically */
  align-items: center; /* Center items horizontally */
  color: white;
}

.image-item img {
  max-width: 50%;
}

.newsunic-container {
  width: 100%;           /* Use 100% of the viewport width */
  max-width: 600px;     /* Maximum width of the container */
  margin: 0 auto;       
  background-color: black;
}

.description-box {
  white-space: pre-wrap; /* Keeps whitespace formatting for descriptions */
  padding: 2px;
  border-radius: 4px;
  line-height: 1.5rem;
  font-size: 17px;
  padding-top: 10px;
  font-family: sans-serif;
  width: 95%; /* Adjust width as needed */
  margin: 1px;
  overflow-wrap: break-word; /* Breaks the word to prevent overflow */
  word-wrap: break-word; /* Legacy browsers */
  white-space: pre-wrap; /* Maintains whitespace formatting and wraps text */
  overflow-y: auto; 
  font-weight: bold;
}

.category-box {
  padding: 2px 4px;
  border-radius: 5px;
  color: white;
  display: inline-block; /* Display tags in a line */
  margin: 2px;
  font-size: 14px;
}

/* Example color coding by category */
.digitalizzazione { background-color: #007bff; }
.crisi-impresa { background-color: #dc3545; }
.imprenditoria-femminile { background-color: #e83e8c; }
.Innovazione-ricerca { background-color:#1a6330; }
.Sostegno-investimenti { background-color: #5762d8; }
.Sostegno-liquid { background-color: #880937; }
.Startup-impresa { background-color: #52155b; }
.Crisi-impresa { background-color: #b86464; }
.Inclusione-sociale { background-color: #565412; }
.Imprenditoria-giovanile { background-color: #1d3376; }
.Internazionalizzazione { background-color: #802f85; }
.Transizione-ecologica { background-color:#09461b; }
.default-category { background-color:#4c4e4d; }
/* Add more categories similarly */

.button-stylish {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 10px 0 10px 0;
  font-size: 20px;
  font-weight:900;
}

.button-stylish2 {
  cursor: pointer;
  background-color: white;
  color: #092f7a;
  border: 5px solid #FF5758;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 10px 0 10px 0;
  font-size: 22px;
  font-weight:bold;
    cursor: pointer;
    width: 40%;

}

.button-stylish:hover {
  background-color: #FF5758; /* Darker grey on hover */
}

.nuovo-box {
  position: absolute;
  top: 1px;
  right: 1px;
  color: #FF5758;
  padding: 1px 3px;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  background-color: white;
  font-size: 20px;
}


.criteria {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap; /* Keeps formatting for spaces and newlines but allows wrapping */
  max-width: 100%; /* Ensure the container does not exceed its parent's width */
  margin-bottom: 15px;
  border-radius: 5px;
  border-left: 14px solid #092f7a;
  padding-left: 5px;
}

.criteria pre {
  white-space: pre-wrap;       /* Allow line breaks */
  word-wrap: break-word;       /* Break long words */
  overflow-x: auto;            /* Add horizontal scroll */
  max-width: 100%;             /* Limit width to prevent overflow */
}

.spinner-tu {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-tut {
  display: inline-block;
  height: 20px;
  width: 80px; /* Adjust width based on dot size */
}

.spinner-tut .dot {
  height: 11px;
  width: 11px;
  background-color: #ccc; /* Light grey */
  border-radius: 50%;
  display: inline-block;
  animation: dots 2.4s infinite;
}

.spinner-tut .dot:nth-child(1) { animation-delay: -0.32s; }
.spinner-tut .dot:nth-child(2) { animation-delay: -0.16s; }
.spinner-tut .dot:nth-child(3) { animation-delay: 0s; }

@keyframes dots {
  0%, 80%, 100% {
    background-color: #ccc; /* Light grey */
  }
  40% {
    background-color: #333; /* Dark grey */
  }
}


.agent2-containers{
    margin: 2px;
    text-align: center;
    font-size: 15px;
    padding: 10px;
    max-width: "600px";
    box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
    border-radius: 5px;
}

.agent2-containers-comm{
  margin: 2px;
  text-align: center;
  font-size: 15px;
  padding: 10px;
  max-width: "600px";
}

.agent2-containers-comm2{
  margin: 2px;
  text-align: center;
  font-size: 15px;
  padding: 10px;
  background-color: white;
  max-width: "600px";
  color: #092f7a;
}

.p{
  font-weight: normal;
}

.tooltip-box {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip text - adjust as needed */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-box:hover .tooltip-text {
  visibility: visible;
}

.spinner-sendsearch{
  border: 4px solid rgba(25, 188, 77, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: rgb(191, 0, 255);
    animation: spin 1s ease infinite;
}


.subscription-container {
  font-family: Arial, sans-serif;
  background-color: #092f7a;
  color: #fff;
  margin: 0;
  padding: 0;
}

.subscription-header {
  text-align: center;
  padding: 20px;
  background-color: #092f7a;
  color: #fff;
  max-width: 600px;
  margin: 10px auto;
}

.subscription-header h1 {
  margin: 0;
  font-size: 2em;
}

.subscription-header p {
  margin: 10px 0 0;
  font-size: 1.2em;
}

.subscription-piani {
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  padding: 2px;
  box-Shadow: 2px 2px 10px rgb(255, 255, 255);
}

.subscription-pianoed {
  box-Shadow: 2px 2px 10px rgb(255, 255, 255);
}

.subscription-subject {
  font-size: 16px;
  text-align: left;
  padding-left: 20px;
  padding-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  padding-top: 10px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .subscription-piani {
    display: inline-block;
  }
  .step-box{
    font-size: 15px;
  font-family: 'Poppins', sans-serif;
  }
  .incentivethread-subdetails{
    margin-top: 40px;
    margin: 2px;
  
    background: white;
    color: #092f7a;
    padding: 10px 10px 0 5px;
    border: 2px solid white;
    border-radius: 5px;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-wrap: wrap; /* Ensures content wraps on smaller screens */
    gap: 10px; /* Adds space between the image and details */
    flex-direction: column; /* Stacks items vertically on mobile */
    align-items: center; /* Centers the content on mobile */
  }
  .incentivethread-subdetails-unicid{
    margin: 2px;
    color: white;
    padding: 0 20px 0 30px;
    font-size: 18px;
  }
  .incentivethread-subdetails-unicid2{
    margin: 2px;
    color: white;
    font-size: 18px;
    padding: 0 20px 0 20px;
  }
  .incentivethread-subdetails-unicid3{
    color: white;
    padding: 0 20px 0 20px;
    font-size: 20px;
    font-weight: bold;
  }
  .sloganContainer{
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .threadunic-container-landing {
    margin: 0 auto;        /* Top and bottom margin 0, left and right auto */
    background-color: white;
    color: white; 
  }
  .title-searched{
    font-size: 25px;
  }
}

@media (min-width: 769px) {
  .threadunic-container-landing {
    max-width: 600px;     /* Maximum width of the container */
    margin: 0 auto;        /* Top and bottom margin 0, left and right auto */
    background-color: white;
    color: white; 
  }
  .subscription-piani {
    display:flex;
  }
  .step-box{
    font-size: 22px;
  font-family: 'Poppins', sans-serif;
  width: 80%;
  }
  .incentivethread-subdetails{
    margin: 2px;
    margin-top: 1px;
    background: white;
    color: #092f7a;
    padding: 10px 20px 0 15px;
    border: 2px solid white;
    border-radius: 5px;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-wrap: wrap; /* Ensures content wraps on smaller screens */
    align-items: center; /* Align items at the top */
    gap: 10px; /* Adds space between the image and details */
  }

  .incentivethread-subdetails-unicid{
    margin: 2px;
    color: white;
    padding: 0 20px 0 30px;
    font-size: 18px;
  }
  .incentivethread-subdetails-unicid2{
    margin: 2px;
    color: white;
    font-size: 18px;
    max-width: 50%;
  }
  .incentivethread-subdetails-unicid3{
    color: white;
    padding: 0 50px 0 30px;
    font-size: 35px;
    max-width: 50%;
    font-weight: bold;
  }
  .title-searched{
    font-size: 25px;
  }
}

.subscription-piano {
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  padding: 20px;
  text-align: center;
  flex: 1;
  max-width: 300px;
  margin: 20px;
}

.subscription-piano h2 {
  font-size: 1.5em;
  color: #FF5758;
  font-family: 'Poppings', sans-serif;
  font-weight: bold;
}

.subscription-prezzo {
  font-family: 'Poppings', sans-serif;
  color: grey;
  margin: 0 10px 0 0; /* Space between price and button */
}

.subscription-prezzo span {
  text-decoration: line-through;
  color: #999;
}

.subscription-piano.subscription-completo {
  width: 100%;
}

.addon-row {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 10px; /* Optional spacing */
}

.subscription-piano ul {
  list-style: none;
  padding: 0;
  margin: 1px 0;
}

.subscription-piano ul li {
  margin: 10px 0;
  text-align: left;
}

.addonit-button {
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  background: #007bff;
  color: #fff;
  border-radius: 4px;
}

.subscription-button {
  display: inline-block;
  padding: 5px 5px;
  background-color: #FF5758;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  border-color: #FF5758;
  margin: 10px;
}

.subscription-button:hover {
  background-color: #FF5758;
}

.subscription-footer {
  text-align: center;
  padding: 20px;
  background-color: #FF5758;
  color: #fff;
}

.subscription-footer p {
  margin: 0 0 10px;
}

.subscription-footer .subscription-button {
  background-color: #fff;
  color: #FF5758;
}

.subscription-tick {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-size: 14px;
}

.subscription-tick i {
  margin-right: 10px; /* Adjust the spacing as needed */
}

/* Styles for the container of the elements */
.container-searchi {
  display: block;
  margin-bottom: 20px;
  width: 400px;
}

/* Styles for input fields */
.input-field-searchi {
  display: block;
  width: 90%; /* Adjust width as needed */
  padding: 8px 10px;
  margin-top: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-field-searchi {
  display: block;
  width: 50%; /* Adjust width as needed */
  padding: 8px 10px;
  margin-top: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Styles for select dropdown */
.select-dropdown-searchi {
  display: block;
  width: 94%; /* Adjust width as needed */
  padding: 8px 10px;
  margin-top: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.select-dropdown-searchij {
  display: block;
  width: 100%;
  padding: 8px 10px;
  margin-top: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}


/* Placeholder styling */
::placeholder {
  color: black;
}

/* Styles for the button */
.button-searchi {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #3973b0; /* Bootstrap blue */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px; 
}

.button-searchi:hover {
  background-color: #FF5758; /* Darker blue */
}

.button-searchi:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(91, 111, 133, 0.5);
}

@media (max-width: 768px) {
  .button-searchintroi {
    font-size: 14px;
    padding: 5px 10px;
  }
  .button-searchintros {
    font-size: 14px;
    padding: 2px 8px;
  }
}

@media (min-width: 769px) {
  .button-searchintroi {
    font-size: 16px;
    padding: 5px 10px;
  }
  .button-searchintros {
    font-size: 16px;
    padding: 2px 8px;
  }
}

.button-searchintroi {
  color: white;
  background-color: black; /* Bootstrap blue */
  border: 2px solid white;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px; 
  font-family: 'Poppins', sans-serif;
}

.button-searchintros {
  color: white;
  background-color: #3973b0; /* Bootstrap blue */
  border: 2px solid white;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px; 
  font-family: 'Poppins', sans-serif;
}


.button-searchintroi:hover {
  background-color: #FF5758; /* Darker blue */
}

.button-searchintroi:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(91, 111, 133, 0.5);
}

.frontpage-container{
  margin: 20px;
}


/* signup*/
.popup-su {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Fixed positioning for the overlay */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; /* Ensure it is on top of other content */
}

.popup-content-su {
  background-color: #bdaeae; /* Background color for the message box */
  padding: 30px;
  border-color: white;
  border-radius: 8px;
  text-align: center;
  position: relative; /* Relative positioning for the close button */
}

.close-button-su {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  font-size: 22px;
  cursor: pointer;
}


/* info button search page*/
.toggle-switch-container {
  display: flex;
  color: #092f7a;
  margin-right: 50px;
}

.toggle-switch-container-profs {
  display: flex;
  color: #092f7a;
  margin-right: 5px;
}

.toggle-switch-container-paid {
  display: flex;
  color: white;
  margin-right: 50px;
  padding-top: 10px;
  font-size: 18px;
}

.toggle-switch-container-price {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  margin-top: 10px;
}

.info-icon {
  position: relative;
  display: inline-block;
  margin-left: 10px; 
  cursor: pointer;
  font-size: 18px;
  border-radius: 100%; /* Ensures a perfect circle */
  border: 1px solid white; /* Define the border: color, width, and style */
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #092f7a; /* Dark blue background */
  color: white; /* Red text color */
  padding: 12px;
}

.info-icon:hover .tooltip-texty {
  visibility: visible;
  opacity: 1;
}

.tooltip-texty {
  visibility: hidden;
  width: 200px;
  background-color: #092f7a;
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 105%; 
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 15px;
}


@media (max-width: 768px) {
.filters-searchx {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-bottom: 0;
  padding-left: 20px;
  color: white;
  align-items: center;
  gap: 10px;
}
.frontpage-container{
  width: 100%;
}
.button-stylishy {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 10px 0 10px 0;
  font-size: 28px;
  font-weight:bold;
}
}

@media (min-width: 768px) {
  .filters-searchx {
    display: flex; /* Use flexbox for layout */
    flex-direction: row; /* Change to row for two columns */
    flex-wrap: wrap; /* Allow items to wrap */
    padding-right: 20px;
    padding-bottom: 0;
    padding-left: 20px;
    color: white;
    justify-content: center; /* Center the content */
    gap: 10px;
  }
  .button-stylishy {
    cursor: pointer;
    background-color: white;
    color: black;
    border: 1px solid white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin: 10px 0 10px 0;
    font-size: 28px;
    font-weight:bold;
  }
  }

  .goal-list {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between the list items */
    padding: 20px;
    max-width: 400px;
    margin: 0 auto; /* Center the list horizontally */
  }
  
  .goal-list-item {
    background-color: #fff; /* White background */
    padding: 15px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    width: 80%; /* Ensures same width for all items */
    display: flex;
    justify-content: space-between; /* Optional: space between goal and any buttons */
    align-items: center;
    font-size: 1rem;
    color: #333;
    transition: box-shadow 0.2s ease; /* Smooth shadow on hover */
  }

  .tick-icon {
    background-color: #4caf50; /* Green background */
    color: white; /* White tick color */
    border-radius: 50%; /* Circular shape */
    width: 24px; /* Adjust width and height for size */
    height: 24px; /* Adjust width and height for size */
    display: flex; /* Flex to center the icon */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-size: 16px; /* Adjust size of the tick */
    display: flex; /* Flex to center the icon */
    justify-content: center; /* Center horizontally */
  }
  .question-icon {
    background-color: #eba937; /* Green background */
    color: white; /* White tick color */
    border-radius: 50%; /* Circular shape */
    width: 24px; /* Adjust width and height for size */
    height: 24px; /* Adjust width and height for size */
    display: flex; /* Flex to center the icon */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-size: 16px; /* Adjust size of the tick */
    margin-left: 10px; /* Space between the heading and the question icon */
    font-size: 1.5rem; /* Adjust font size as needed */
  }

  .cross-icon {
    background-color: #FF5758; /* Red background */
    color: white; /* White icon color */
    border-radius: 50%; /* Circular shape */
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-left: 20px;
    cursor: pointer;

    /* Box shadow for depth */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    
    /* Transition effect for smooth interaction */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.cross-icon:hover {
    /* Scale effect on hover */
    transform: scale(1.1);

    /* Increase shadow on hover for emphasis */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}


  .goal-content {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 4px;
    width: 100%;
  }
  
  .goal-content p {
    margin: 0;
    padding-right: 10px;
    flex: 1;
  }

  .icon-container {
    display: flex; /* Use flexbox for icon alignment */
    align-items: center; /* Center icons vertically */
  }

  .goal-list-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
  
  .goal-list-item p {
    margin: 0; /* Remove default margin for list items */
  }
  

  .goal-input-container {
    display: flex;
  flex-direction: row; /* Align items horizontally */
  align-items: center; /* Vertically center the items */
  gap: 10px; /* Space between input and button */
  max-width: 400px;
  margin: 20px auto;
  }
  
  .goal-input,
  .goal-button {
    background-color: #fff; /* White background */
    padding: 15px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    border: 1px solid #ddd; /* Light border */
    font-size: 1rem;
    color: #333;
    transition: box-shadow 0.2s ease; /* Smooth shadow on hover */
    flex: 1;
  }
  
  .goal-input:hover,
  .goal-button:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
  
  .goal-button {
    cursor: pointer; /* Pointer cursor to indicate it's clickable */
    background-color: #007bff; /* Button background color */
    color: white; /* White text */
    border: none;
  }
  
  .goal-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  

  .goals-container {
    background-color: #fff; /* White background */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    max-width: 400px;
    margin: 10px auto; /* Center the container */
    text-align: left; /* Align text to the left */
    color: #092f7a;
  }

  .goals-containerttl {
    background-color: #fff; /* White background */
    padding: 5px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    max-width: 400px;
    text-align: center; /* Align text to the left */
    color: #092f7a;
  }

  .goals-container h3 {
    font-size: 1.5rem;
    color: #333;
  }

  .proposed-goals-container {
    background-color: #fff; /* White background */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    max-width: 400px;
    margin: 20px auto; /* Center the container */
    text-align: left; /* Align text to the left */
  }
  
  .proposed-goals-container h3 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .goal-list {
    list-style-type: disc; /* Bullets for the list items */
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between the list items */
    padding: 10px;
    max-width: 400px;
    margin: 0 auto; 
  }
  
  .goal-list-item {
    background-color: #f9f9f9; /* Light gray background for each item */
    margin: 5px 0; /* Spacing between list items */
    padding: 10px;
    border-radius: 5px; /* Slightly rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Small shadow for each item */
    font-size: 1rem;
    color: #333;
    transition: box-shadow 0.2s ease;
    justify-content: space-between; /* Space between goal and icons */
    align-items: center; /* Align items vertically center */
  }

  .explain-list-item {
    background-color: #f9f9f9; /* Light gray background for each item */
    margin: 1px 0; /* Spacing between list items */
    padding: 1px;
    border-radius: 5px; /* Slightly rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Small shadow for each item */
    font-size: 1rem;
    color: #333;
    transition: box-shadow 0.2s ease;
    justify-content: space-between; /* Space between goal and icons */
    align-items: center; /* Align items vertically center */
  }

  .header-container {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    margin-bottom: 15px; /* Space between header and list */
  }
  
  .goal-list-item:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Hover effect on list items */
  }

  .goal-item-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  

  .modal-overlay-sign {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .modal-content-sign {
    background-color: white;
    padding: 20px;
    border: 2px solid white;
    border-radius: 10px;
    text-align: center;
    max-width: 250px; /* Adjust box size if needed */
    box-shadow: 0 10px 30px rgba(253, 252, 252, 0.1);
  }
  
  .modal-title-sign {
    color: #092f7a;
    font-family: 'Poppins', sans-serif;
    font-size: 20px; /* Ensure text is visible */
    font-weight: bold;
    box-shadow: 0 10px 30px rgba(251, 251, 251, 0.1);
  }
  
  .modal-button-sign {
    margin-top: 20px;
    background-color: #092f7a;
    color: white;
    padding: 10px 20px;
    border: 2px solid #092f7a;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .modal-button-sign:hover {
    background-color: #0a5db2; /* Change to a lighter shade on hover */
    transform: scale(1.05); /* Slightly increase size on hover */
  }

  .logout-button {
    background-color: #FF5758;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .move-button {
    background-color: green; /* Use the inline color here */
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 80px;
    width: 180px;
    font-size: 40px;
    position: fixed;
    bottom: 30%; /* 20% from the bottom of the viewport */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center the button */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition */
    box-shadow: 0 1px 3px rgba(242, 239, 239, 0.1);
}

/* Optional hover effect */
.move-button:hover {
    background-color: #092f7a; /* Changes color on hover */
}


  .input-field-searchi::placeholder {
    color: grey; /* Set placeholder text color */
}

/* Container Styles */
.agent2comm-goals1 {
  margin-top: 2px;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Header Styles */
.agent2comm-goals2 {
  color: #092f7a;
  margin-bottom: 15px;
  font-size: 25px;
  font-weight: bold;
  text-align: center; /* Centers the header text */
}

/* Inner Container Styles */
.agent2comm-goals3 {
  display: flex;
  flex-direction: column; /* Stacks children vertically */
}

/* Unordered List Styles */
.agent2comm-goals4 {
  list-style-type: none; /* Removes default bullets */
  padding-left: 0;
  color: #555;
}

/* List Item Styles */
.agent2comm-goals5 {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Goal Text Styles */
.agent2comm-goals6 {
  font-size: 18px;
}

/* Tick Icon Styles */
.agent2comm-goals7 {
  color: green;
  font-size: 20px;
  margin-right: 15px;
  cursor: default; /* Indicates non-interactive */
}

/* Remove Button Styles */
.agent2comm-goals8 {
  background-color: transparent;
  border: none;
  color: #e74c3c;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
}

.agent2comm-goals8:hover {
  color: #c0392b; /* Darker red on hover for visual feedback */
}

/* No Goals Message Styles */
.agent2comm-goals9 {
  color: #777;
  font-style: italic;
  text-align: center; /* Centers the message text */
}

/* Spinner Styles */
.spinner-wait {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f; /* Customize the color as needed */
  animation: spin 1s linear infinite;
  margin: auto; /* Centers the spinner horizontally */
  color: white;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}


.success-notification {
  text-align: center;
  margin-top: 50px;
}

.proceed-button-successpay {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.proceed-button-successpay:hover {
  background-color: #45a049;
}


.success-notification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f9fc;
}

.success-notification-content {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  max-width: 600px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
}

.success-header {
  font-size: 2.2rem;
  color: #092f7a;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}

.success-image {
  width: 100%;
  max-width: 250px;
  margin: 20px 0;
  border-radius: 8px;
}

.success-message {
  font-size: 1.1rem;
  color: #333333;
  line-height: 1.6;
  margin-bottom: 30px;
  font-family: 'Poppins', sans-serif;
}

.proceed-button-successpay {
  padding: 12px 24px;
  font-size: 1.2rem;
  background-color: #092f7a;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.proceed-button-successpay:hover {
  background-color: #4c7bd8;
}


.filter-services-box {
  display: block;
    margin: 3px auto;
    padding: 5px 10px;
    background-color: #8c4caf;
    color: white;
    border-radius: 25px;
    cursor: pointer;
    border: none;
    font-size: 12px;
}

.restricted-filters-services {
    background-color: #8c4caf;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    align-items: center;
    font-size: 20px;
    color: white;
    border: 2px solid white;
    max-width: 260px;
}

.open-filters-services {
  background-color: grey;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 15px;
  }


  .filters-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    border: 4px solid grey;
}

.filters-wrapper-profs {
  display: grid;
  grid-template-columns: 1fr; /* Default to 1 column */
  gap: 15px;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  border: 4px solid grey;
}

/* Apply 2 columns for desktop screens (768px and up) */
@media (max-width: 768px) {
  .filters-wrapper-profs {
    grid-template-columns: repeat(1, 1fr); /* Two columns for larger screens */
  }
}

@media (min-width: 1401px) {
  .filters-wrapper-profs {
    grid-template-columns: repeat(4, 1fr); /* Two columns for larger screens */
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .filters-wrapper-profs {
    grid-template-columns: repeat(3, 1fr); /* Two columns for larger screens */
  }
}

.custom-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.5); /* Faded border */
  border-top: 3px solid white; /* Active part of spinner */
  border-radius: 50%; /* Circular shape */
  animation: custom-spin 1s linear infinite; /* Continuous rotation */
}

@keyframes custom-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 769px) {
.goalplus-box {
    margin-left: 10px;
    flex: 1;
    padding: 10px;
    font-size: 20px;
  }
.mapflex{
  display:flex;
  width: 95%;
}  
}

@media (max-width: 768px) {
  .goalplus-box {
      margin-left: 10px;
      flex: 1;
      padding: 6px;
      font-size: 20px;
    }
    .mapflex{
      display: list-item;
      width: 95%;
    }  
  }
  .search-results {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
    position: absolute;
    width: 100%; /* Match the input box's width */
    box-sizing: border-box;
    border-radius: 5px; /* Add rounded corners */
  }
  
  .search-results li {
    padding: 8px 12px; /* Adjust padding for spacing */
    cursor: pointer;
    color: black;
    text-align: left;
    word-wrap: break-word; /* Allow text to wrap */
    white-space: normal; /* Ensure text wraps properly */
    background-color: white; /* Default background */
  }
  
  .search-results li:nth-child(odd) {
    background-color: #f9f9f9; /* Light grey background for alternate rows */
  }
  
  .search-results li:nth-child(even) {
    background-color: white; /* White background for alternate rows */
  }
  
  .search-results li:hover {
    background-color: #e8e8e8; /* Slightly darker grey on hover */
  }
  
  .search-container {
    position: relative; /* Ensure dropdown is positioned relative to the input */
    padding: 10px;
    width: 100%;
    box-shadow:#3498db;
        border-radius: 5px;
  }

  .search-container-free {
    position: relative; /* Ensure dropdown is positioned relative to the input */
    padding: 1px;
    width: 100%;
    box-shadow:#3498db;
        border-radius: 5px;
  }
  
  input[type="text"] {
    width: 100%; /* Input matches container width */
    padding: 8px 12px;
    font-size: 20px;
    border: 1px solid #ccc;
    border-radius: 5px; /* Rounded corners */
    box-sizing: border-box;
  }
  
  
  .dropdown-container-profsLV {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: left; 
    padding-left: 20px;
    width: 100%;
  }
  
  .level-dropdownLV {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    width: 150px;
  }
  
  .info-iconLV {
    font-size: 12px;
    color: #888;
    position: relative;
    cursor: help;
  }
  
  .tooltip-textyLV {
    display: none;
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 10;
  }
  
  .info-iconLV:hover .tooltip-texty {
    display: block;
  }


  .custom-headingHJ {
    color: white;
    text-align: center;
    font-size: 16px;
    line-height: 2.5rem;
    margin: 0 auto;
    letter-spacing: 2px;
    font-family: sans-serif;
  }
  


/* .step-box {
  background-color: #092f7a; 
  color: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 0 auto;
} */

/* Hover effect for boxes */
.step-box:hover {
  background-color: #FF5758; /* Darker Blue */
  transform: translateY(-5px);
  color: white;
}

/* Style for the arrow between steps */
.step-arrow {
  width: 10px;
  height: 10px;
  border-top: 2px solid black;
  border-right: 2px solid black;
  transform: rotate(45deg);
  margin: 10px auto;
}

.step-container {
  text-align: center;
  margin-bottom: 20px;
}

.step-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin: 0 auto 10px; /* Center the number and add space below */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.percorso-heading-container {
  position: relative; /* To position "Info Chiave" absolutely within this container */
  width: 95%;
}

.percorso-heading {
  text-align: left;
  justify-content: center;
  align-items: center;
  margin: 5px 5px 0 5px; /* Top, right, bottom, left */
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  color: #FF5758;
  padding-left: 10px;
  padding-top: 10px;
} 

.attention-box {
  margin-top: 10px;
  padding: 10px;
  background-color: #ffebeb;
  border-left: 14px solid #b326ae;
  border-radius: 4px;
  color: #b326ae;
  font-weight: bold;
  font-size: 14px;
}

/* Sidebar layout styles */
.sidebar-layout {
  display: flex;
}

.sidebar {
  width: 250px;
  background-color: #f4f4f4;
  padding: 10px 0 0 10px ;
}

.content {
  flex-grow: 1;
  padding: 0 10px 0 0;
}

/* Stage indicator styles */
.stage-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 5px;
}

.stage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  border: 2px solid #ddd;
}

.circle.completed {
  background-color: #4caf50;
  border-color: #4caf50;
}

.circle.active {
  background-color: #ece451;
  border-color: #2196f3;
}

.circle.pending {
  background-color: #f9f9f9;
  border-color: #ddd;
}

.tick {
  font-size: 16px;
  color: white;
}

.tick-completed {
  color: white;
}

.tick-pending {
  color: grey;
}

.label {
  font-size: 14px;
  color: #333;
}


@media (max-width: 768px) {
  .sidebar {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      transform: translateX(-100%);
      z-index: 999;
  }

  .content {
      margin-left: 0;
  }

  .toggle-button {
      display: block;
  }
}

/* Grid for steps */
.steps-grid {
  display: grid;
  gap: 3px; /* Adjust the gap between boxes */
  grid-template-columns: repeat(3, 1fr); /* 3 columns on larger screens */
  margin-top: 20px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .steps-grid {
      grid-template-columns: 1fr; /* 1 column on mobile */
  }
  .tags-button {
    margin-bottom: 20px;
  }
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-number {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.step-box {
  background-color: #f4f4f4; /* Box background color */
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  min-height: 10rem;
}

.addon-price-button {
  display: flex;
  align-items: center;
}

.green-cell {
  background-color: green;
  color: white;
  padding: 5px;
  border-radius: 5%;
  text-align: center;
}

.analisi-filters{
  padding-left: 20px;
  display: flex;
  gap: 40px;
}

@media (max-width: 768px) {
  .analisi-filters{
    display:block;
  }
}

@media (min-width: 769px) {
  .analisi-filters{
    display: flex;
  }
  .centered-box {
    color: white;
    text-align: center;
    font-size: 22px;
    line-height: 2rem;
    width: 50%;
    margin: 20px auto;
    background-color: #333;
    padding: 15px;
    border-radius: 8px;
  }
  .centered-cvbox {
    color: white;
    text-align: center;
    font-size: 22px;
    line-height: 2rem;
    width: 50%;
    margin: 20px auto;
    background-color: black ;
    padding: 15px;
    border-radius: 8px;
  }
  .profile-field {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
  }
  .profile-card {
    background-color: #2c2c2c;
    color: white;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    width: 600px;
    transition: all 0.3s ease;
  }
  .rfq-box {
    background-color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 600px;
    text-align: left;
    margin: 20px;
  }
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}

/* Sidebar container */
.sidebar1 {
  position: fixed;
  top: 0;
  right: -300px; /* Hide off the right */
  width: 300px;  /* Fixed width */
  height: 100%;
  background-color: #333;
  color: white;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  z-index: 1000;
}

.sidebar1.open {
  right: 0; /* Slide in */
}

@media (max-width: 768px) {
  .sidebar1 {
    width: 250px;
    right: -250px;
  }
}


/* Close button */
.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

/* Sidebar links */
.sidebar1-links {
  list-style: none;
  padding: 0;
}

.sidebar1-links li {
  margin: 15px 0;
}

.sidebar1-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.sidebar1-links a:hover {
  text-decoration: underline;
}

/* Open button */
.open-btn {
  background-color: white;
  color: #333;
  border: none;
  font-size: 28px;
  padding: 10px 15px;
  cursor: pointer;
  margin-left: 10px; /* Align next to FaUserCircle */
}

.open-btn:hover {
  background-color: white;
}

/* Sidebar Styles */
.sidebarmg {
  margin-bottom: 20px;
  padding-top: 20px;
  margin-top: 20px; 
  max-width: 600px;
  min-width: 600px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  background: linear-gradient(to bottom right, #ffffff, #f0f2f5);
}

/* Mobile view: Full-width */
@media (max-width: 600px) {
  .sidebarmg {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}


.sidebarmg h3 {
  margin-bottom: 10px;
  color: white;
}

/* Style for message list */
.sidebarmg-item {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sidebarmg-item:hover {
  background-color: #f0f2f5;
}

.sidebarmg-item.active {
  background-color: #d1ecf1;
  border-left: 5px solid #007BFF;
}

.sidebarmg-item p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.timestampmg {
  font-size: 12px;
  color: #666;
}

/* Main Content Styles */
.main-contentmg {
  flex: 1;
  padding-left: 20px;
}

.main-contentmg h3 {
  font-size: 30px;
  margin-bottom: 15px;
  color: white;
}

.main-contentmm h3 {
  font-size: 30px;
  margin-bottom: 15px;
  color: white;
}

.messagemg-details {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 20px;
}

.messagemg-details p {
  font-size: 16px;
  margin-bottom: 10px;
}

.messagemg-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.textareamg {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.buttonmg {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.buttonmg:hover {
  background-color: #0056b3;
}


@media print {
  .no-print {
    display: none !important;
  }
  
  /* Remove margins and padding that might cause page breaks */
  .print-body {
    margin: 0;
    padding: 0;
  }

  /* Hide browser-generated footers/headers (in some browsers) */
  @page {
    margin: 0;
  }
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
}

.styled-table th, .styled-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.styled-table th {
  background-color: #007bff;
  color: white;
}

.styled-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.styled-table tr:hover {
  background-color: #ddd;
}

.container-scout {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: black;
}

/* General table styling */
.table-piva {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid white;
}

.thead-piva {
  display: table-header-group; /* Show headers on larger screens */
}

.tbody-piva tr {
  display: table-row; /* Default row layout for desktop */
  border-bottom: 1px solid white;
}

.tbody-piva td {
  padding: 8px;
  border: 1px solid white;
  color: white;
  text-align: center;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .thead-piva {
    display: none; /* Hide table headers on mobile */
  }

  .tbody-piva tr {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid white;
    padding: 10px;
  }

  .tbody-piva td {
    flex: 1 1 25%; /* Default: 4 columns per row */
  }

  .tbody-piva td:nth-child(1),
  .tbody-piva td:nth-child(2),
  .tbody-piva td:nth-child(3),
  .tbody-piva td:nth-child(4) {
    flex: 1 1 50%; /* First 4 columns take 50% width each (2 per row) */
  }

  .tbody-piva td:nth-child(5),
  .tbody-piva td:nth-child(6),
  .tbody-piva td:nth-child(7) {
    flex: 1 1 33.33%; /* Last 3 columns take equal width in next row */
  }
  .messagesmg-container {
    display: block !important;
    font-family: Arial, sans-serif;
    margin: 10px;
    padding: 5px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .messagesmg-container {
    display: block !important;
  }
}


.messagesmg-container {
  display: flex;
  font-family: Arial, sans-serif;
  margin: 10px;
  padding: 5px;
  background-color: black;
  gap: 10%;
}

/* Container for the entire email interface */
.email-container {
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  background: black;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: 3px solid white; /* Red border */
}

/* Row for recipient and sender email inputs */
.email-inputs-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Input styling */
.email-input {
  flex: 1;
  margin-right: 15px;
}

.email-input label {
  display: block;
  margin-bottom: 5px;
  color: #007BFF;
  font-weight: bold;
}

.email-input input {
  width: 100%;
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.email-input input:focus {
  border-color: #007BFF;
  outline: none;
}

/* Message section */
.message-sections {
  margin-top: 20px;
}

/* Message boxes for intro, body, and signoff */
.message-box {
  margin-bottom: 20px;
}

.message-box label {
  display: block;
  margin-bottom: 5px;
  color: #007BFF;
  font-weight: bold;
}

.message-box textarea {
  width: 100%;
  height: 120px;
  padding: 15px;
  border: 2px solid #ccc;
  border-radius: 10px;
  resize: vertical;
  box-sizing: border-box;
  font-size: 1em;
  transition: border-color 0.3s;
}

.message-box textarea:focus {
  border-color: #092f7a;
  outline: none;
}

/* Email preview */
.email-preview {
  min-height: 150px;
  padding: 15px;
  border: 2px dashed #092f7a;
  border-radius: 10px;
  background-color: lightpink;
  margin-bottom: 20px;
  color: #333;
}

/* Buttons */
.generate-button {
  background-color: #092f7a;
  color: #fff;
  border: 1px solid white;
  border-radius: 10px;
  padding: 12px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
  box-shadow: 0 5px 10px rgba(0, 123, 255, 0.3);
  margin-right: 20px;
}

.generate-button:hover {
  background-color: #0056b3;
}

.send-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
  transition: background-color 0.3s;
  box-shadow: 0 6px 12px rgba(40, 167, 69, 0.3);
}

.send-button:hover {
  background-color: #218838;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .email-inputs-row {
    flex-direction: column;
  }

  .email-input {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .profile-field {
    display: block;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
  }
}

/* TAGS section - sliding from the top */
.tags-section {
  position: fixed;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: top 0.5s ease;
  z-index: 1000;
  border-bottom: 2px solid #092f7a;
}

/* Mobile view adjustments */
@media (max-width: 600px) {
  .tags-section {
    top: -800px;
    height: 500px;
    font-size: 11px;
    overflow-y: auto;
  }

  .tags-section.open {
    top: 0;
  }
  .centered-box {
    color: white;
    text-align: center;
    font-size: 22px;
    line-height: 2rem;
    width: 90%;
    margin: 20px auto;
    background-color: #333;
    padding: 15px;
    border-radius: 8px;
  }
  .centered-cvbox {
    color: white;
    text-align: center;
    font-size: 22px;
    line-height: 2rem;
    width: 90%;
    margin: 20px auto;
    background-color: black ;
    padding: 15px;
    border-radius: 8px;
  }
  .profile-card {
    background-color: #2c2c2c;
    color: white;
    border-radius: 12px;
    padding: 6px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    width: 600px;
    transition: all 0.3s ease;
  }
  .rfq-box {
    background-color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 7px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: left;
    margin: 5px;
  }
}


.tags-section.show {
  top: 0;
}

.tags-content {
  padding: 20px;
}

.tags-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.tags-table th,
.tags-table td {
  border: 1px solid #092f7a;
  padding: 8px;
  text-align: left;
}

.delete-button {
  margin-right: 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #c0392b;
}

.toggle-button {
  padding: 5px 10px;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 5px;
}

.toggle-button.selected {
  background-color: #28a745;
}

.toggle-button:not(.selected) {
  background-color: #6c757d;
}

.tags-button {
  background-color: #6c757d;
  color: #fff;
  border: 1px solid white;
  border-radius: 10px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
  box-shadow: 0 5px 10px rgba(108, 117, 125, 0.3);
  margin-right: 20px;
}

.tags-button:hover {
  background-color: #495057;
}



/* Container for the message details */
.message-details-container {
  margin-top: 20px;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #007BFF;
  background-color: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.message-details-container h3 {
  color: #007BFF;
  margin-bottom: 15px;
}

/* Toggle button */
.toggle-details-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
  box-shadow: 0 5px 10px rgba(0, 123, 255, 0.3);
  margin-bottom: 10px;
}

.toggle-details-button:hover {
  background-color: #0056b3;
}

/* Sliding panel */
.details-panel {
  position: fixed;
  top: 33%;
  left: -400px; /* Initially hidden */
  width: 400px;
  height: 50%;
  background-color: #ffffff;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.2);
  border-right: 5px solid #007BFF;
  transition: left 0.5s ease;
  padding: 20px;
  z-index: 1001;
  overflow-y: auto;
}

.details-panel.open {
  left: 0; /* Slide in from the left */
}

.close-details-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1em;
  box-shadow: 0 4px 6px rgba(231, 76, 60, 0.4);
  transition: background-color 0.3s;
}

.close-details-button:hover {
  background-color: #c0392b;
}

/* Message details content */
.message-details-content {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 10px;
  border: 1px solid #bbb; /* Light grey border */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  background-color: lightgray; /* Light grey background */
  padding: 20px;
  color: #333;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease;
}

.message-details-content h4 {
  color: rgb(164, 143, 143);
  margin-bottom: 15px;
  font-size: 16px;
  margin-bottom: 10px;
}

.message-html {
  border: 1px dashed #ccc;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: white;
  color: grey;
  max-width: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}


.timestamp {
  display: block;
  margin-top: 10px;
  color: #6c757d;
  font-size: 0.85em;
}

/* Optional: Fade-out animation when closing */
.details-panel.close {
  animation: slideOutRight 0.5s forwards;
}

@keyframes slideOutRight {
  from {
    right: 0;
  }
  to {
    right: -400px;
  }
}


.messages-comm2 {
  text-align: center;  /* Center the title */
  font-size: 45px;     /* Large text size */
  font-weight: bold;   /* Make it bold */
  color: #333;         /* Dark grey color */
  margin: 20px 0;      /* Add spacing above and below */
  padding: 10px;       /* Add some padding */
  text-transform: uppercase; /* Optional: Makes it all caps */
  letter-spacing: 2px; /* Space out letters slightly */
  border-bottom: 3px solid #007BFF; /* Optional: Underline effect */
  display: inline-block; /* Keeps underline only as wide as text */
}

/* Style for all input placeholders */
input::placeholder,
textarea::placeholder {
  color: grey; /* Light grey placeholder */
  opacity: 1; /* Ensure visibility */
  font-style: italic; /* Optional: Slightly different style */
}


.kpi-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.kpi-box {
  background-color: #f0f0f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 30px 20px;
  text-align: center;
  width: 150px;
  transition: transform 0.2s ease;
}

.kpi-box:hover {
  transform: translateY(-5px);
}

.kpi-number {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #333;
}

.kpi-label {
  font-size: 1rem;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* RFQ Heading */
.rfq-heading {
  font-size: 1.5rem;
  color: #f9f9f9;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
  background-color: #333;

}

/* Hover effect for the box */
.rfq-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.rfq-button-container {
  position: fixed;
  top: 50%; /* Center vertically */
  left: -400px; /* Start off-screen to the left */
  transform: translateY(-50%);
  transition: left 0.5s ease-in-out;
  z-index: 9999; /* Make sure it appears above other content */
  border-radius: 5%;
  border-color: 1px solid grey;
}

.rfq-button-container.active {
  left: 20px; /* Slides in from the left */
}

/* RFQ Button */
.rfq-button {
  background-color: #FF5758;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: block;
  margin: 20px auto 0 auto; /* Centered button */
}

/* Button Hover */
.rfq-button:hover {
  background-color: #e04b4c;
  transform: scale(1.05);
}

/* Button Focus */
.rfq-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 87, 88, 0.4);
}

/* RFQ Text Styling */
.rfq-box p {
  margin: 8px 0;
  color: #555;
  font-size: 0.95rem;
}

/* Email Input Styling */
.rfq-email-input {
  width: 100%;
  padding: 10px;
  margin: 12px 0;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

/* Focus Effect */
.rfq-email-input:focus {
  border-color: #FF5758;
  outline: none;
  box-shadow: 0 0 5px rgba(255, 87, 88, 0.5);
}

.avatar-confirmation {
  text-align: center;
  margin-top: 20px;
}

.selected-avatar-preview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #FF5758;
  margin-top: 10px;
}

.selected-avatar-previewRFQ {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #FF5758;
  margin-top: 10px;
}

.profile-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}


.profile-card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
}

/* Profile Heading */
.profile-heading {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  color: #ff5758;
}


.field-label {
  width: 120px;
  font-weight: bold;
  color: lightgray;
  text-transform: uppercase;
  flex-shrink: 0;
}

.field-content {
  background-color: #3c3c3c;
  padding: 10px 15px;
  border-radius: 8px;
  flex-grow: 1;
  color: #f1f1f1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.field-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

.field-content li {
  margin-bottom: 5px;
}

/* Summary Box with Rotated Label */
.summary-box {
  position: relative;
}

/* Services container */
.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

/* Individual service box */
.service-box {
  background-color: #FF5758; /* Default background */
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, background-color 0.3s ease;
  cursor: pointer;
  display: flex;
  justify-content: flex-start; 
  align-items: flex-start; 
  text-align: left; 
  width: 100%; 
  flex-wrap: wrap; 
  word-break: break-word;
}

/* Hover effect */
.service-box:hover {
  transform: scale(1.05);
  background-color: #cc4747;
}

/* No services message */
.no-services {
  color: #FF5758;
  font-style: italic;
}

/* Main Container */
.rfq-page-container {
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  background-color: black;
}

/* Card Container */
.rfq-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: transform 0.2s ease-in-out;
  max-width: 50%;
}

.rfq-card2 {
  border-color: 1px solid red;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 16px;

}

.rfq-card:hover {
  transform: translateY(-4px);
}

/* Incentive Title */
.rfq-title {
  background-color: #f5f5f5;
  padding: 12px;
  border-radius: 6px;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  color: #333;
}

/* Submission Date */
.rfq-submission-date {
  font-size: 0.9rem;
  color: #777;
}

/* Company Info Section */
.rfq-company-info p {
  margin: 0;
  word-wrap: break-word; /* Wrap long text like ATECO */
}

/* ATECO Field Styling */
.rfq-ateco {
  white-space: pre-wrap; /* Wrap ATECO over multiple lines */
}

/* Accept/Reject and SF/UF Section */
.rfq-action-container {
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

/* Buttons */
.accept-btn,
.reject-btn,
.confirm-submit-btn {
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 120px;
  text-align: center;
}

.accept-btn {
  background-color: #4caf50;
  color: white;
}

.accept-btn:hover {
  background-color: #45a049;
}

.reject-btn {
  background-color: #f44336;
  color: white;
}

.reject-btn:hover {
  background-color: #d32f2f;
}

.confirm-submit-btn {
  background-color: #2196f3;
  color: white;
}

.confirm-submit-btn:hover {
  background-color: #1976d2;
}

/* Input Fields */
.rfq-input-container input[type="number"] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: 4px;
}

/* Label for Inputs */
.rfq-input-container label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
}

/* Rejected Message */
.rfq-rejected {
  color: #f44336;
  font-weight: bold;
  text-align: center;
}

/* SF/UF Values Container */
.rfq-values-container {
  background-color: #e3f2fd;
  padding: 12px;
  border-radius: 6px;
  text-align: center;
  font-weight: bold;
  background-color: #c3c3c3;
  padding: 12px;
  border-radius: 6px;
  width: 80%; /* Smaller width */
  margin: 10px 0;
}


.red-box {
  background-color: #ffe5e5;
  padding: 12px;
  border-radius: 6px;
  width: 80%; /* Smaller width */
  margin: 10px 0;
}

/* Lowercase Field Names */
.rfq-company-info strong {
  text-transform: lowercase;
}

.rfq-submission-date,
.rfq-response-date {
  font-size: 0.9rem;
  color: #777;
  margin-top: 5px;
}

/* Response Date (below blue box) */
.rfq-response-date {
  margin-top: 12px;
}

.rfq-table {
  color: white;
  width: 100%;
  border-collapse: collapse;
}

.rfq-table tr {
  margin-bottom: 15px;
  border: 1px solid white;
  padding: 10px;
}

.rfq-table td {
  text-align: right;
  padding: 8px;
  border-bottom: 1px solid white;
}

@media (max-width: 768px) {
  .rfq-table thead {
    display: none;
  }

  .rfq-table tr {
    display: block;
    margin-bottom: 15px;
    border: 1px solid white;
    padding: 10px;
  }

  .rfq-table td {
    display: block;
    text-align: right;
    padding: 8px;
    border-bottom: 1px solid white;
  }

  .rfq-table td::before {
    content: attr(data-label);
    font-weight: bold;
    float: left;
    text-transform: uppercase;
  }
}

.responsive-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 15px;
  margin: 20px 0;
}

.responsive-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .responsive-table,
  .responsive-table tbody,
  .responsive-table tr,
  .responsive-table td {
    display: block;
    width: 100%;
  }

  .responsive-table td:first-child {
    font-weight: bold;
    font-size: 18px;
    background: #f8f8f8;
    border: none;
    padding-top: 12px;
  }

  .responsive-table td:last-child {
    border-top: none;
  }
}

.objectivesproprof {
  font-size: 18px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: black;
  color: white;
  width: 60%;
  margin: auto;
  text-align: center; /* Ensures text is centered */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .objectivesproprof {
    width: 95%; /* Make it wider on smaller screens */
    font-size: 16px; /* Slightly smaller font */
  }
}

.conferma-buts {
  display: flex;
  width: 50%;
  margin: auto;
  justify-content: center; /* Centers buttons inside the div */
  gap: 10px; /* Adds spacing between buttons */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .conferma-buts {
    width: 100%; /* Make it wider on smaller screens */
    align-items: center; /* Center buttons */
  }
}
