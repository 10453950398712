.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-container {
  width: 100px;
  /* Set a fixed width as needed */
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide text that overflows the container */
  text-overflow: ellipsis;
  /* Show an ellipsis (...) when the text overflows */
  text-align: left;
  /* Align text to the left */
}

#moving-text {
  display: inline-block;
  white-space: nowrap;
}

.cursor {
  display: inline-block;
  width: 1ch;
  animation: flicker 2s infinite;
  margin-bottom: 2px;
}

@keyframes flicker {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.flash-text {
  display: inline-flex;
  font-size: 24px;
}

.letter {
  animation: flash 2s infinite;
  opacity: 0;
}

@keyframes flash {

  0%,
  80% {
    opacity: 0;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Monoton&family=Roboto:wght@300&family=Tomorrow:wght@300&family=Tourney:wght@100&family=Wallpoet&display=swap');

/* Your existing CSS styles go here */
.text-title {
  font-family: 'Poppins', sans-serif;
  /* Apply the imported font to your styles */
}

/* Your existing CSS styles go here */
.text-loop {
  font-family: 'Poppins', sans-serif;
  object-fit: cover;
  top: 0;
  right: 10;
  flex-grow: 1;
  color: #FF5758;
  font-size: 25px;
  text-align: left;
  background: white;
  text-align: center;
  display: inline-block;
  margin-top: 0.5px;
  font-family: "Inter", sans-serif;
}

.type-fonts {
  font-family: 'Poppins', sans-serif;
  font-weight: bolder;
}

@media (max-width: 600px) {

  .text-loop {
    font-size: 24px;
  }

  .offer-button {
    font-size: 13px;
  }

  .lister {
    font-size: 14px;
  }

  .icon-header {
    font-size: 13px;
  }

  .type-fonts {
    font-size: 14px;
  }

  .email {
    font-size: 14px;
  }

  .login-button {
    font-size: 10px; 
  }
}

@media (min-width: 601px) {

  .text-loop {
    font-size: 58px;
  }

  .offer-button {
    font-size: 23px;
  }

  .lister {
    font-size: 24px;
  }

  .icon-header {
    font-size: 23px;
  }

  .margin-landing {
    padding: 1px;
    margin-top: 1px;
    font-weight: bold;
  }

  .type-fonts {
    font-size: 24px;
  }

  .email {
    font-size: 24px;
  }

  .login-button {
    font-size: 24px;
  }
}

@keyframes Flash {
  0%,
  80%

  /* up to 43s of 45s */
    {
    opacity: 0;
    /* Keep the text/content invisible */
  }

  80%,
  100%

  /* last 2s of 45s */
    {
    opacity: 1;
    /* Make the text/content visible */
    background-color: #092f7a;
    /* Assuming you want pink background for text visibility */
  }
}

.image-psu img {
  width: 100%; /* Makes the image take the full width of the container */
  height: auto; /* Maintains aspect ratio */
  object-fit: contain; /* Ensures the image fits within the container without cropping */
  max-width: 100%; /* Prevents the image from growing beyond its container */
}


.image-fp-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: sans-serif;
  padding: 20px 0 0 0;
}

.image-item {
  width: 25%;
  /* 4 items per row */
  display: flex;
  /* Use flexbox for vertical alignment */
  flex-direction: column;
  /* Stack image and text vertically */
  align-items: center;
  /* Center items horizontally */
}

.image-item img {
  max-width: 50%;
}

/* Add other CSS styles as needed */

.general-css {
  border-color: #092f7a;
  padding-bottom: '30px';
  padding-top: '30px';
  background-color: #092f7a;

}

.disclaimer-modal {
  position: fixed;
  /* Fixed position keeps it in place during scrolling */
  left: 0;
  right: 0;
  bottom: 0;
  /* Align to the bottom of the viewport */
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  align-items: flex-end;
  /* Aligns the modal content to the bottom */
  justify-content: center;
  /* Centers the content horizontally */
  z-index: 1000;
  /* Ensure it's above other content */
  padding: 20px;
  /* Add padding to ensure it doesn't stick to the screen edges */
}


.modal-content {
  background-color: grey;
  color: white;
  padding: 20px;
  border-radius: 10px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Optional: adds a shadow effect */
  width: 80%;
  /* Adjust width as needed */
  max-width: 500px;
  /* Maximum width */
  border: 1px solid white;
  justify-content: space-between;
  font-size: 14px;
}

.modal-content button {
  background-color: white;
  color: black;
  border: 1px solid rgb(0, 0, 0);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
  justify-content: space-between;
}

.modalrfq-content {
  background-color: grey;
  color: white;
  padding: 20px;
  border-radius: 10px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Optional: adds a shadow effect */
  /* Adjust width as needed */
  /* Maximum width */
  border: 1px solid white;
  justify-content: space-between;
  font-size: 14px;
}

.login-button {
  color: black;
  background-color: white;
  align-items: center;
  cursor: pointer;
  width: 200px;
  padding: 5px;
  border: none;
}

.footer-button {
  color: white;
  background-color: black;
  align-items: center;
  cursor: pointer;
  width: 200px;
  padding: 5px;
  border: none;
}

.offer-button {
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  background-color: black;
  align-items: center;
  cursor: pointer;
  margin-left: 1px;
  font-family: 'Poppins', sans-serif;
  font-weight: bolder;
  text-align: center;
}

.container {
  display: flex;
  justify-content: space-around;
  /* This will space out your columns evenly across the container */
  align-items: start;
  /* Aligns items at the start of the container */
}

.container1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: black;
  z-index: 3;
  padding-top: 5px;
}

.column {
  display: flex;
  flex-direction: column;
  /* Stack the image and button vertically */
  align-items: center;
  /* Center-align the items horizontally */
  text-align: center;
  /* Ensure text within is centered, affecting the button text */
  margin-top: 20px;
  margin-bottom: 20px;
}

.column1 {
  display: flex;
  flex-direction: column;
  /* Stack the image and button vertically */
  align-items: center;
  /* Center-align the items horizontally */
  text-align: center;
  /* Ensure text within is centered, affecting the button text */
  border-color: white;
}

.lister {
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
  padding: 4px;
  position: sticky;
  top: 53px;
  z-index: 100;
  animation: flash 45s infinite;
}

.video-container {
  border: 1px solid white;
  border-radius: 2rem;
  box-shadow: 0 8px 16px rgba(246, 244, 244, 0.2);
  margin: 2%;
  position: relative;
  /* Establishes a positioning context */
  width: 100%;

  /* Adjust the height as needed */
  overflow: hidden;
  /* Ensures the content fits within the bounds */
}

.overlay-text {
  position: absolute;
  /* Positions the text absolutely within the container */
  top: 50%;
  /* Centers the text vertically */
  left: 50%;
  /* Centers the text horizontally */
  transform: translate(-50%, -50%);
  /* Adjusts the positioning to truly center */
  color: white;
  /* Adjusts the text color */
  z-index: 2;
  /* Ensures the text appears above the video */
  font-family: 'Poppins', sans-serif;

}


@media (max-width: 600px) {
  .overlay-text {
    font-size: 35px;
    /* Adjusts the text size */
  }

  .newchat {
    margin-top: 30px;
    font-size: 18px;
    text-align: center;
    margin: 20px;
    margin-bottom: 50px;
    color: #092f7a;
  }

  .chat-continua {
    font-size: 24px;
    margin: 20px;
    text-align: left;
    margin-top: 40px;
    color: grey;
  }
}

@media (min-width: 1090px) {
  .overlay-text {
    font-size: 65px;
    /* Adjusts the text size */
  }

  .newchat {
    margin-top: 30px;
    font-size: 45px;
    text-align: left;
    margin: 50px;
    margin-left: 10%;
    color: #092f7a;
  }

  .chat-continua {
    font-size: 47px;
    margin: 10px;
    margin-left: 10%;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 30px;
    color: grey;
  }
}

.icon-header {
  color: white;
  background: black;
  width: 100%;
  display: inline-block;
  font-weight: bolder;
  border-top: solid black 1px;
  text-align: center;
  padding-left: 20px;
  padding: 5px;
  font-family: 'Poppins', sans-serif;
}

.email {
  color: white;
  background: #FF5758;
  width: 100%;
  display: inline-block;
  text-align: center;
  border-color: rgb(225, 141, 23);
  font-family: 'Poppins', sans-serif;
  height: 2rem;
  font-size: 18px;
}

.sources {
  color: white;
  background: black;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.capdeets {
  color: white;
  background: black;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  padding-top: 2px;
  padding-bottom: 10px;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding: 5px;
}

.dropdown-content {
  display: block; /* Ensure it can be displayed */
  position: absolute; /* Position it relative to the parent */
  background-color: #092f7a; /* Set background color to blue */
  color: white; /* Change text color to white for better contrast */
  padding: 10px; /* Add padding for better spacing */
  border-radius: 4px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  z-index: 100; /* Make sure it's on top of other elements */
  margin-top: 200px; 
}

/* Optional: Style for dropdown items */
.dropdown-item {
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Light hover effect */
}

/* new stuff */

.question-item {
  text-align: left;
  margin-top: 5px;
  padding: 5px;
}

.question-items {
  text-align: left;
  margin-top: 5px;
  padding: 5px;
  width: 90%;
}

.full-display {
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
}

@media (min-width: 768px) {
  .full-display {
    margin-left: 2px;
    margin-right: 2px;
  }

  .table-frontpage td {
    font-size: 25px;
  }

  .table-frontpage th {
    font-size: 25px;
  }

  .news-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;
  }

  .oldnews-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;
    max-width: 600px;
  }

  .frontp-title {
    font-size: 38px;
  }

  .frontp-summary {
    padding: 1%;
    font-size: 22px;
  }

  .banner-image {
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: relative;
    border-top: 1px solid #092f7a;
    border-bottom: 1px solid #092f7a;
  }

  .chat-area {
    margin-top: 10px;
    height: 3px;
    /* Thickness of the line */
    width: 80%;
    /* Full width of the container */
    background-color: #092f7a;
    /* Color of the line */
  }

  .notizia {
    font-size: 48px;
    font-family: sans-serif;
    font-weight: bolder;
    text-align: center;
    color: white;
    background: black;
    margin: 0;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0px 80px 0px;
  }

  .solutions {
    font-size: 48px;
    font-family: sans-serif;
    font-weight: bolder;
    text-align: center;
    color: white;
    background: #FF5758;
    margin: 0;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0px 80px 0px;
  }

  .aiutare {
    font-size: 38px;
    font-family: sans-serif;
    font-weight: bolder;
    text-align: center;
    color: #092f7a;
    background: white;
    margin: 0;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0px 10px 0px;
    border: none;
  }

  .news-title {
    font-size: 18px;
    text-align: left;
  }

  .image-article {
    width: 70%;
  }

  .image-article-unicid {
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .image-article-searchid {
    margin-top: 40px;
    max-width: 300px; /* Restricts the size of the image */
    width: 100%; /* Makes it responsive */
    object-fit: cover; /* Keeps image aspect ratio */
    border-radius: 8px; /* Adds rounded corners if desired */
    border: 1px solid rgb(220, 214, 214);
  }

  .image-prolog {
    width: 20%; /* Makes it responsive */
    object-fit: cover; /* Keeps image aspect ratio */
    border-radius: 8px; /* Adds rounded corners if desired */
    border: 1px solid rgb(220, 214, 214);
  }

  .article-title {
    width: 90%;
    text-align: left;
    color: #FF5758;
    background: black;
  }

  .article-summary {
    margin-bottom: 20px;
    width: 95%;
    color: white;
    font-size: 18px;
  }
  .news-article-unic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    width: 80%;
  }
  .digithon {
    width: 20%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .digithon {
    width: 80%;
    height: auto;
  }
  .full-display {
    margin-left: 2px;
    margin-right: 2px;
  }

  .table-frontpage td {
    font-size: 12px;
  }

  .table-frontpage tdh {
    font-size: 12px;
  }

  .news-container {
    justify-content: center;
    background: black;
    padding: 20px;
  }

  .frontp-title {
    font-size: 24px;
  }

  .frontp-summary {
    padding: 1%;
    font-size: 16px;
  }

  .banner-image {
    width: 100%;
    height: 33px;
    overflow: hidden;
    position: relative;
    border-top: 1px solid #092f7a;
    border-bottom: 1px solid #092f7a;
  }

  .chat-area {
    margin-top: 10px;
    height: 3px;
    /* Thickness of the line */
    width: 80%;
    /* Full width of the container */
    background-color: #092f7a;
    /* Color of the line */
  }

  .notizia {
    font-size: 24px;
    font-family: sans-serif;
    text-align: center;
    color: white;
    height: 5vh;
    background: black;
    padding: 30px 0px 30px 0px;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .solutions {
    font-size: 24px;
    font-family: sans-serif;
    text-align: center;
    color: white;
    height: 5vh;
    background: #FF5758;
    padding: 30px 0px 30px 0px;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .aiutare {
    font-size: 28px;
    font-family: sans-serif;
    font-weight: bolder;
    text-align: center;
    color: #092f7a;
    background: white;
    margin: 0;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px 20px 10px;
    border: none;
  }

  .news-title {
    font-size: 16px;
    text-align: left;
  }

  .image-article {
    width: 90%;
  }

  .image-article-unicid {
    width: 90%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .image-article-searchid {
    max-width: 300px; /* Restricts the size of the image */
    width: 100%; /* Makes it responsive */
    object-fit: cover; /* Keeps image aspect ratio */
    border-radius: 8px; /* Adds rounded corners if desired */
  }
  .image-prolog {
    margin-top: 40px;
    height: 50px;
    max-width: 300px; /* Restricts the size of the image */
    width: 50%; /* Makes it responsive */
    object-fit: cover; /* Keeps image aspect ratio */
    border-radius: 8px; /* Adds rounded corners if desired */
    border: 1px solid rgb(220, 214, 214);
  }

  .article-title {
    width: 90%;
    text-align: left;
    color: #FF5758;
  }

  .article-summary {
    margin-bottom: 20px;
    width: 95%;
    color: white;
    font-size: 14px;
  }
  .news-article-unic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    width: 100%;
  }
}

.input-container {
  display: flex;
  position: relative;
  width: calc(95% - 20px);
  margin: 0 auto;
}

.details {
  margin-top: 5px;
  flex: 1; /* Allows the details to take up remaining space */
  min-width: 200px; /* Ensures a minimum width for the details */
}

.submit-button {
  border-radius: 0 3px 3px 0;
  background-color: white;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.chatbox-style {
  text-align: left;
  display: block;
  margin: 0 auto;
  width: calc(100% - 20px);
  padding: 10px;
  text-align: left;
  box-sizing: border-box;
  height: 2.5vw;
}

.chatbox-style:focus {
  outline: none;
}

.table-frontpage table {
  width: 80%;
  border-collapse: collapse;
}

.table-frontpage th {
  background-color: #f5f5f5;
  /* Light grey background for header */
  text-align: center;
  padding: 10px;
}

.table-frontpage td {
  color: white;
  /* White text color */
  padding: 10px;
  border-radius: 8px;
  /* Rounded corners for the cells */
  border: 1px solid white;
}

.table-frontpage tr {
  transition: background-color 0.2s ease-in-out;
}

.table-frontpage td:nth-child(1) {
  background-color: #5da0bf;
  /* Shade of grey for the first column */
}

.table-frontpage td:nth-child(2) {
  background-color: #285075;
  /* Shade of grey for the second column */
}

.table-frontpage td:nth-child(3) {
  background-color: #989898;
  /* Shade of grey for the third column */
}

.table-frontpage tr:hover {
  background-color: #a9a9a9;
  /* Slightly lighter grey when hovering */
}

.table-frontpage td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.table-frontpage td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.line-container {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 5vh;
  /* Full height of the viewport */
  margin-bottom: 10px;
}

.news-article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
}

.oldnews-article {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-bottom: 25px;
  width: 100%;
}




/* video*/
/*MOBILE*/
@media (max-width: 600px) {
  .video-responsive {
  border-radius: 1rem;
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
  }
  .side-content {
    padding: 20px; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
    border-radius: 8px; /* Optional: Rounded corners */
    display: flex;
    height: 30vh; /* Full viewport height */
    width: 100vw;  /* Full viewport width */
    background-color: #f0f0f0; /* Optional: Background color for visualization */
  }
  .container-flex {
    width: 100%; /* Ensures it takes full width */
  }
  .landing-width{
    position: relative; /* Makes this div a positioning context for absolute elements */
      display: flex; /* As previously set */
      background: white;
      width: 100%;
  }
  .video-container {
    width: 100%;
    max-width: 800px; /* Optional: max width of the video */
    margin: auto; /* Center the video */
    position: relative; /* Required for absolute positioning of the play button */
    padding: 20px;
  }
  .brand-logo-fp {
    height: 20px; /* Adjust logo height as needed */
    /* Add any additional styles for the logo */
    padding-left: 10px;
  }
  .brandbot-logo-fp {
    height: 200px; /* Adjust logo height as needed */
    /* Add any additional styles for the logo */
    padding-left: 10px;
  }
  .brand-name-fp {
    /* Add any additional styles for the logo */
    padding-left: 10px;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 8px;
  }
  .introsearch-text {
    font-size: 16px;
    line-height: 1.5rem;
  }
}

/*TABLET*/
@media (min-width: 600px) and (max-width: 1200px) {
  .video-responsive {
    border-radius: 1.5rem; 
    width: 100%;
    max-width: 100%;
    object-fit: cover; 
    object-position: center;
    display: block;
  }
  .side-content {
    padding-top: 40px;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    background: white;
    padding-right: 20px;
  }
  .container-flex {
    width: 100%; /* Ensures it takes full width */
  }
  .landing-width{
    position: relative; /* Makes this div a positioning context for absolute elements */
      display: flex; /* As previously set */
      background: white;
  }
  .video-container {
    width: 100%;
    max-width: 800px; /* Optional: max width of the video */
    margin: auto; /* Center the video */
    position: relative; /* Required for absolute positioning of the play button */
  }
  .brand-logo-fp {
    height: 28px; /* Adjust logo height as needed */
    /* Add any additional styles for the logo */
    padding-left: 30px;
  }
  .brandbot-logo-fp {
    height: 128px; /* Adjust logo height as needed */
    /* Add any additional styles for the logo */
    padding-left: 30px;
  }
  .brand-name-fp {
    /* Add any additional styles for the logo */
    padding-left: 15px;
    font-size: 28px;
    font-weight: 900;
    letter-spacing: 12px;
  }
}

/*DESKTOP*/
  @media (min-width: 1200px) {
    .introsearch-text {
      font-size: 22px;
      line-height: 2.5rem;
    }
    .video-responsive {
    border-radius: 2rem;
      width: 100%;
      height: 50vh;
      /* Adjust height as necessary */
      object-fit: cover;
      object-position: bottom;
      /* Focus on the bottom right part */
    }
    .side-content {
      padding-top: 2px;
      margin-left: 20px;
      width: 40%;
      display: flex; /* Optional, depending on your content alignment needs */
      align-items: center; /* Centers content vertically */
      justify-content: center; /* Centers content horizontally */
      background: white;
      height: 2vh; /* Full viewport height */
      width: 80vw; 
    }
    .side-contentv {
      margin-left: 20px;
      width: 40%;
      display: flex; /* Optional, depending on your content alignment needs */
      align-items: center; /* Centers content vertically */
      justify-content: center; /* Centers content horizontally */
      background: white;
      height: 50vh; /* Full viewport height */
      width: 80vw; 
      margin-top: 20px;
      padding-top: 10px;
      margin-bottom: 20px;
    }
    .container-flex {
      display: flex;
      width: 100%; /* Ensures it takes full width */
      align-items: center;
      margin-bottom: 20px;
    }
    .landing-width{
      position: relative; /* Makes this div a positioning context for absolute elements */
        display: flex;
        flex-direction: column;
        width: 60%; /* As previously set */
        background: white;
    }
    .video-container {
      width: 100%;
      max-width: 800px; /* Optional: max width of the video */
      margin-right: 20px; /* Center the video */
      position: relative; /* Required for absolute positioning of the play button */
    }
    .brand-logo-fp {
      height: 28px; /* Adjust logo height as needed */
      /* Add any additional styles for the logo */
      padding-left: 30px;
    }
    .brandbot-logo-fp {
      height: 228px; /* Adjust logo height as needed */
      /* Add any additional styles for the logo */
      padding-left: 30px;
    }
    .brand-name-fp {
      /* Add any additional styles for the logo */
      padding-left: 15px;
      font-size: 36px;
      font-weight: 900;
      letter-spacing: 12px;
    }
  }



  
  .video-responsive {
    display: block;
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    border: 1px solid grey;
    object-fit: cover;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 70px; 
    height: 70px; /* Height of the button */
    background-color: black; /* Circle background color */
    border: none; /* No border */
    border-radius: 50%; /* Make it circular */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* Pointer cursor on hover */
    z-index: 1; /* Ensure button is above video */
    outline: none; /* Remove outline */
    opacity: 0.5; /* Set opacity to 50% */
  }
  
  .play-icon {
    width: 0;
    height: 0;
    border-left: 15px solid rgb(249, 245, 245); /* Create red triangle */
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
  
  .play-button:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Darker shade on hover */
  }
  
  .header-container {
    display: flex; /* Use flexbox for horizontal alignment */
    justify-content: space-between; /* Space out items */
    align-items: center; /* Center items vertically */
    padding: 10px 20px; /* Add some padding */
    background-color: white; /* Background color for the header */
  }
  
  .headerStyles {
    background: none; /* Remove default button styling */
    border: none; /* Remove border */
    cursor: pointer; /* Change cursor on hover */
  }
  
  .menuToggleContainer {
    cursor: pointer; /* Change cursor on hover */
  }
  
  /* Additional styles for the menu toggle if needed */
  

/* Container for the entire component */
.partner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  font-family: 'Poppins', sans-serif;
  background-color: #f0f4f8;
}

/* Hero Section */
.hero-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  background-color: #f7f7f7;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.hero-section .text-section {
  text-align: center;
  padding: 0 20px;
}

.hero-section h1 {
  font-size: 32px;
  color: #092f7a;
  margin-bottom: 20px;
  font-weight: 700;
}

.hero-section .intro-text {
  font-size: 20px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 20px;
}

.hero-section .intro-text2 {
  font-size: 20px;
  line-height: 1.6;
  color: #FF5758;
  margin-bottom: 20px;
}

.hero-section .cta-message {
  font-size: 18px;
  font-weight: bold;
  color: #FF5758;
  margin-bottom: 15px;
}

.hero-section2 h1 {
  font-size: 32px;
  color: #092f7a;
  margin-bottom: 20px;
  font-weight: 700;
}

.hero-section2 {
  display: flex; /* Use flexbox for proper alignment */
  flex-direction: column; /* Stack the text and images vertically */
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center text content */
  padding: 20px; /* Add some padding for spacing */
}

.text-section2 {
  margin-bottom: 20px; /* Space between the text and images */
}

.image-section {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start; /* Align horizontally to the left */
  align-items: flex-start; /* Align vertically to the top */
  height: auto; /* Ensure container doesn't force extra height */
}


.image-section img {
  max-width: 100%;
  border-radius: 10px;
  width: 70%;
  max-width: 500px;
  height: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  margin: 20px;
}

@media (min-width: 700px) {
.image-section2 {
  display: flex;
  flex-wrap: wrap; /* Allow images to wrap if needed */
  justify-content: center; /* Center the images horizontally */
  gap: 20px; /* Add space between the images */
  cursor: pointer;
}
.image-section2 img {
  max-width: 100%;
  border-radius: 10px;
  width: 40%;
  max-width: 250px;
  height: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, background-color 0.3s ease;
}
}

@media (max-width: 699px) {
  .image-section2 {
    display: flex; /* Flexbox layout */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center the images horizontally */
    gap: 20px; /* Add space between the images */
    cursor: pointer; /* Optional: Adds pointer cursor for clickable images */
  }
  .image-section2 img {
    max-width: 100%;
    border-radius: 10px;
    width: 100%;
    max-width: 250px;
    height: auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  }


.image-section2 img:hover {
  background-color: #FF5758; /* Red */
  transform: translateY(-5px); /* Lift effect */
  border-radius: 10px; /* Ensure corners stay rounded */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Enhance shadow effect */
  padding: 10px; /* Add space between image and background */
}


/* Form Section */
.form-section {
  width: 100%;
  max-width: 900px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.form-section h2 {
  font-size: 28px;
  color: #092f7a;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
}

.form-section p {
  font-size: 18px;
  color: #333;
  text-align: center;
  margin-bottom: 25px;
}

.highlight {
  color: #FF5758;
  font-weight: bold;
}

/* Form Styling */
.partner-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.partner-form label {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: #092f7a;
}

.partner-form input,
.partner-form select,
.partner-form textarea {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;
}

.partner-form input:focus,
.partner-form select:focus,
.partner-form textarea:focus {
  border-color: #092f7a;
}

/* Textarea Styling */
.partner-form textarea {
  resize: vertical;
  min-height: 100px;
}

/* Submit Button */
.partner-form button {
  background-color: #FF5758;
  color: white;
  font-size: 20px;
  padding: 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.partner-form button:hover {
  background-color: #d44747;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
  }

  .hero-section .text-section {
    padding: 0 10px;
  }

  .form-section {
    padding: 20px;
  }

  .hero-section h1,
  .form-section h2 {
    font-size: 24px;
  }
  .topyadj {
    margin-top: 60px;
    color: grey;
    font-size: 14px;
  }
  .topyadjf {
    margin-top: 6px;
    color: grey;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .filterfreebox {
    flex-direction: column; 
    align-items: center; 
    gap: 10px; 
    align-items: center;
    display: flex;
    justify-Content: center;
    margin: 10px 0;
    align-items: center;
    display: flex;
    justify-Content: center;
    margin: 10px 10px;
    align-items: center;
  }
  .filterfreebox-display{
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    align-items: center;
  }

  .searchfreecontainer {
    display: block;
    align-items: center; /* Vertically center items */
    justify-content: space-between; /* Space between items */
    padding: 10px 20px; /* Add padding to the container */
    gap: 20px; /* Space between the elements */
  }
}

@media (min-width: 769px) {
  .hero-section {
    flex-direction: row;
    justify-content: space-between;
  }

  .hero-section .text-section {
    max-width: 50%;
  }

  .image-section {
    max-width: 50%;
  }
  .topyadj {
    margin-top: 60px;
    color: grey;
    font-size: 18px;
  }
  .topyadjf {
    margin-top: 6px;
    color: grey;
    font-size: 18px;
  }
  .filterfreebox {
    display: flex;
    justify-Content: center;
    margin: 10px 0;
    gap: 30px;
    align-items: center;
  }
  .filterfreebox-display{
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    align-items: center;
  }
  .searchfreecontainer {
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: space-between; /* Space between items */
    padding: 10px 20px; /* Add padding to the container */
    gap: 20px; /* Space between the elements */
  }
  
}


  
/* Enhanced general styling for a modern look */
.incentive-guess-game {
  width: 60%;
  max-width: 350px;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 10px;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
}

/* Enhanced button styling for better emphasis */
.incentive-guess-game button {
  background-color: #FF5758; /* Vibrant blue for contrast */
  color: white;
  border: none;
  padding: 15px;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-weight:400;
  letter-spacing: 3px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

@media (min-width: 769px) {
.incentive-guess-game button {
  font-size: 2.4rem;
}
}

@media (max-width: 768px) {
  .incentive-guess-game button {
    font-size: 1.4rem;
  }
  }

.incentive-guess-game button:hover {
  background-color: #092f7a;
  transform: scale(1.02);
}
/* Enhanced styling for the Registrati button */
.register-button {
  margin-top: 20px;
  background: linear-gradient(135deg, #ff8500, #ff5722);
  color: white;
  border: none;
  padding: 15px 30px;
  font-weight: bold;
  font-size: 1.3rem;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0px 8px 20px rgba(255, 87, 34, 0.5);
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  animation: pulse 2s infinite alternate ease-in-out;
}

/* Hover effect for the button */
.register-button:hover {
  background: linear-gradient(135deg, #ff5722, #ff8500);
  transform: scale(1.1);
  box-shadow: 0px 10px 25px rgba(255, 87, 34, 0.7);
}

/* Pulse animation */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 87, 34, 0.5);
  }
  100% {
    box-shadow: 0 0 30px 15px rgba(255, 87, 34, 0);
  }
}

/* Overlay button style for placing over the image */
.overlay-register-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(135deg, #ff8500, #ff5722);
  color: white;
  border: none;
  padding: 15px 25px;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.overlay-register-button:hover {
  background: linear-gradient(135deg, #ff5722, #ff8500);
  transform: scale(1.05);
}

/* Carousel navigation styling */
.carousel {
  margin-top: 30px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  position: relative;
  min-height: 250px; /* Ensure consistent button placement */
}

.carousel-image {
  width: 100%;
  max-width: 320px;
  border-radius: 10px;
  margin-bottom: 15px;
}

/* Form section styling */
.incentive-guess-game label {
  font-weight: 600;
  color: #34495e;
  margin: 15px 0 8px;
  text-align: left;
  width: 100%;
}

.incentive-guess-game select,
.incentive-guess-game input {
  width: 100%;
  padding: 10px;
  margin: 8px 0 20px;
  border: 1px solid #bbb;
  border-radius: 8px;
  font-size: 1rem;
}

/* Enhanced result text styling */
.result-text {
  margin-top: 25px;
  font-size: 1.2rem;
  color: #0077b6;
  font-weight: bold;
  animation: fadeIn 1s ease-in-out;
}

/* Animation for result text */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Highlighted number styling */
.highlighted-number {
  font-weight: bold;
  font-size: 1.5rem;
  color: #ff6b6b;
  text-decoration: underline;
}

/*QUIZZZZ */
/* Main Container for the Quiz Game */
.quiz-game {
  max-width: 600px;
  margin: auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Quiz Title Styling */
.quiz-game h1 {
  font-size: 2em;
  color: #1565c0;
  margin-bottom: 10px;
}

/* Progress Bar Styling */
.quiz-progress-bar {
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
}

.quiz-progress-bar-inner {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
}

/* Question Title Styling */
.quiz-question h2 {
  font-size: 1.4em;
  color: #555;
  margin-bottom: 15px;
}

/* Incentive Title Styling */
.quiz-incentive-title {
  font-size: 1.4em;
  color: #0d47a1;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Quiz Options Container Styling */
.quiz-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* Quiz Option Button Styling */
.quiz-option {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: 8px 0;
  border: 1px solid #ddd;
  background-color: #ffffff;
  border-radius: 6px;
  font-size: 1.1em;
  text-align: left;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
}

/* Correct Answer Styling */
.quiz-option.quiz-correct {
  background-color: #d4edda; /* Light green */
  border-color: #28a745;
  color: #155724;
}

/* Incorrect Answer Styling */
.quiz-option.quiz-incorrect {
  background-color: #f8d7da; /* Light red */
  border-color: #dc3545;
  color: #721c24;
}

/* Hover Effects for Correct and Incorrect Answers */
.quiz-option:hover:not(.quiz-correct):not(.quiz-incorrect) {
  background-color: #f0f0f0;
  border-color: #ccc;
}

.quiz-option.quiz-correct:hover {
  background-color: #d4edda; /* Light green */
  border-color: #28a745;
}

.quiz-option.quiz-incorrect:hover {
  background-color: #f8d7da; /* Light red */
  border-color: #dc3545;
}

/* Quiz Results Styling */
.quiz-results {
  text-align: center;
  color: #333;
  padding: 20px;
}

.quiz-results h2 {
  font-size: 2em;
  color: #1565c0;
  margin-bottom: 10px;
  animation: fadeIn 1s ease-out;
}

.quiz-results p {
  font-size: 1.5em;
  font-weight: bold;
  color: #4caf50;
  margin-bottom: 20px;
}

.quiz-highlighted-number {
  font-weight: bold;
  font-size: 1.5rem;
  color: #ff6b6b;
  text-decoration: underline;
}

/* Button Container for Restart and Register Buttons */
.quiz-button-container {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

/* Restart Quiz Button Styling */
.quiz-restart-button {
  padding: 15px 30px;
  background-color: #e0e0e0;
  color: #555;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
  min-width: 150px;
}

.quiz-restart-button:hover {
  background-color: #bdbdbd;
  color: #333;
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

/* Register Button Styling */
.quiz-register-button {
  padding: 15px 30px;
  background-color: #ff5722;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
  min-width: 150px;
  animation: pulse 2s infinite alternate ease-in-out;
}

.quiz-register-button:hover {
  background-color: #e64a19;
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(255, 87, 34, 0.5);
}

/* Error Message Styling */
.quiz-error {
  color: red;
  font-weight: bold;
  margin-top: 20px;
}

/* Dark Text for Accessibility */
.quiz-dark-text {
  color: #333 !important;
}

/* Sources Section Styling */
.quiz-sources-section {
  font-size: 0.9em;
  color: #666;
  margin-top: 30px;
}

/* Animation for fadeIn Effect */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Pulse Animation for Register Button */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(255, 87, 34, 0.5); }
  100% { box-shadow: 0 0 30px 15px rgba(255, 87, 34, 0); }
}

.guess-form {
  margin-top: 20px;
  transition: opacity 0.3s ease;
  opacity: 1;
}


@keyframes intermittent-spin {
  0% { transform: rotate(0deg); }
  30% { transform: rotate(360deg); } /* Spins once quickly */
  100% { transform: rotate(360deg); } /* Holds the position for a pause */
}

.indovina-button {
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 1.2em;
  color: #fff;
  background-color: #FF5758;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-align: center;
}

.indovina-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.arrow-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.dice {
  display: inline-block;
  animation: intermittent-spin 1.5s ease infinite; /* Faster spin with pauses */
  margin-bottom: 5px;
  font-size: 37px;
  margin-right: 20px;
  text-shadow: 0px 8px 14px rgba(0, 0, 0, 0.3);
}

.introsearch-text {
  color: #092f7a;
  text-align: center;
  margin: 0 auto;
  letter-spacing: 2px;
  font-family: sans-serif;
  max-width: 900px;
}

.toggle-label-white {
  color: white; /* Makes the label text white */
  font-size: 16px; /* Optional: Adjust font size if needed */
}

.social-navigation {
  display: flex;
  list-style: none;
  padding: 20px;
  align-items: center;
  justify-content: center;
  background: black;
  color: white;
}

.social-navigation li {
  margin: 0 10px;
}

.social-navigation a {
  color: inherit;
  text-decoration: none;
  font-size: 24px;
}

.social-navigation a:hover {
  color: #007bff; /* Change to your desired hover color */
}

.confisi-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.confisi-form label {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: #092f7a;
}

.confisi-form input,
.confisi-form select,
.confisi-form textarea {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;
}

.confisi-form input:focus,
.confisi-form select:focus,
.confisi-form textarea:focus {
  border-color: #092f7a;
}

/* Textarea Styling */
.confisi-form textarea {
  resize: vertical;
  min-height: 100px;
}

/* Submit Button */
.confisi-form button {
  background-color: #FF5758;
  color: white;
  font-size: 20px;
  padding: 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.confisi-form button:hover {
  background-color: #d44747;
}

.confisi-form input::placeholder,
.confisi-form textarea::placeholder {
  color: grey; /* Set placeholder text color */
  font-style: italic; /* Optional: Make placeholder text italic */
  opacity: 1; /* Ensure full visibility (default might reduce opacity) */
}

.confisi-form input,
.confisi-form textarea {
  border: 1px solid #ccc; /* Add border for better visual appeal */
  border-radius: 5px; /* Rounded corners */
  padding: 8px; /* Padding for spacing */
  margin-bottom: 10px; /* Space between form elements */
  width: 100%; /* Ensure inputs fill the container */
  box-sizing: border-box; /* Prevent width overflow */
}

@media print {
  .no-print {
    display: none !important;
  }
  
  /* Remove margins and padding that might cause page breaks */
  .print-body {
    margin: 0;
    padding: 0;
  }

  /* Hide browser-generated footers/headers (in some browsers) */
  @page {
    margin: 0;
  }
}

/* For tablet and desktop */
.landing-logos {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  height: 200px;
}

/* For mobile */
@media screen and (max-width: 768px) {
  .landing-logos {
    display: block; /* Remove flex layout */
    height: auto;   /* Remove fixed height */
    width: 100%;    /* Make it full width */
  }
  .angels-logo {
    height: auto;
    width: 60%;
    filter: invert(100%);
  }
  .other-logos {
    height: auto;
    width: 90%; 
  }
}

@media screen and (min-width: 769px) {
  .angels-logo {
    height: auto;
    width: 15%;
    filter: invert(100%);
  }
  .other-logos {
    height: auto;
    width: 30%; 
  }
}

/* Standardize all text blocks */
.article-meta {
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-family: 'sans-serif';
  margin-top: 16px;
  margin-bottom: 16px;
  opacity: 0.9;
  text-align: left;
  margin-left: 20px;
}

.left-border-highlight {
  border-left: 5px solid white;
  padding-left: 16px;
  color: white;
  margin: 6px 0;
  font-size: 2rem;
  text-align: left;
}

/* General paragraph text */
.article-paragraph {
  text-align: left;
  padding: 0 20px;
  margin-bottom: 1.2rem;
  line-height: 1.6rem;
  font-size: 1rem;
  color: white;
}

/* Subheadings */
.article-heading {
  text-align: left;
  padding-left: 20px;
  font-size: 1.3rem;
  margin-top: 40px;
  margin-bottom: 10px;
  color: white;
}

/* Bullet lists */
.article-list {
  list-style-type: disc;
  padding-left: 40px;
  margin-bottom: 1rem;
  color: white;
  font-size: 1rem;
  text-align: left;
}

.article-list li {
  margin-bottom: 0.5rem;
}

/* Image spacing */
.image-article {
  width: 100%;
  max-width: 800px;
  margin: 30px auto;
  display: block;
  border-radius: 10px;
  padding: 10px;
  border-radius: 5%;
}

.flex-responsive-container {
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* optional spacing between items */
  flex-direction: row; /* default: side by side on desktop */
}

/* Mobile: stack items vertically */
@media (max-width: 768px) {
  .flex-responsive-container {
    flex-direction: column;
  }
}
