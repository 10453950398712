@media (min-width: 769px) {
.ph-input1 {
    width: 500px;
    margin-right: 10px;
    height: 40px;
    font-size: 18px;
    background-color: white;
    border-radius: 15px;
    padding-left: 15px;
    color: white;
  }
  .incentive-selector {
    background-color: #666363;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
}

@media (max-width: 768px) {
    .ph-input1 {
      width: 230px;
        margin-right: 2px;
        height: 20px;
        font-size: 12px;
        background-color: white;
        border-radius: 15px;
        padding-left: 15px;
        color: white;
      }
      .incentive-selector {
        background-color: #666363;
        padding: 2px;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 600px;
        margin: auto;
        font-family: Arial, sans-serif;
      }
    }


    .loading-bar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(90deg, #4caf50, #81c784, #4caf50);
      background-size: 200% 100%;
      animation: slide 1.5s linear infinite, color-change 5s linear infinite;
      z-index: 9999;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .loading-text {
      position: absolute;
      top: -20px;
      font-size: 12px;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
    }
    
    @keyframes slide {
      from {
        background-position: 200% 0;
      }
      to {
        background-position: -200% 0;
      }
    }
    
    @keyframes color-change {
      0% {
        background-color: #4caf50;
      }
      50% {
        background-color: #81c784;
      }
      100% {
        background-color: #4caf50;
      }
    }
        

    .avatar-selector {
      text-align: center;
      margin-top: 20px;
    }
    
    .avatar-grid {
      display: flex;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 10px;
    }
    
    .avatar-wrapper {
      position: relative;
    }
    
    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      cursor: pointer;
      border: 3px solid transparent;
      transition: transform 0.2s ease, border-color 0.2s ease;
    }
    
    .avatar:hover {
      transform: scale(1.1);
    }
    
    .avatar.selected {
      border-color: #FF5758;
      transform: scale(1.15);
    }
    
    /* Confirmation Popup */
    .confirmation-popup {
      position: absolute;
      top: 90px;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 6px;
      padding: 10px;
      text-align: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 10;
    }
    
    .confirmation-popup p {
      margin: 0 0 10px 0;
      font-size: 0.9rem;
    }
    
    .confirm-btn,
    .cancel-btn {
      margin: 0 5px;
      padding: 5px 10px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    
    .confirm-btn {
      background-color: #FF5758;
      color: white;
    }
    
    .cancel-btn {
      background-color: #ccc;
      color: black;
    }
    
    /* Search Box */
    .search-box {
      width: 100%;
      padding: 10px;
      margin-bottom: 5px;
      border-radius: 6px;
      border: 1px solid #ccc;
      position: relative;
    }
    
    /* Suggestions Dropdown */
    .suggestions-dropdown {
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 150px;
      overflow-y: auto;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: grey;
      position: absolute;
      width: 100%;
      z-index: 10;
      color: #092f7a;
    }
    
    .suggestion-item {
      padding: 8px 12px;
      cursor: pointer;
    }
    
    .suggestion-item:hover {
      background-color: #f0f0f0;
    }
    
    /* Selected Incentives */
    .selected-incentives-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 15px;
    }
    
    /* Service-style box for selected incentives */
    .service-box {
      background-color: #007bff;
      color: white;
      padding: 8px 12px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: bold;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      position: relative;
    }

    .incentive-box {
      background-color: #FF5758;
      color: white;
      padding: 8px 12px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: bold;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      position: relative;
    }
    
    .remove-incentive {
      position: absolute;
      top: 5px;
      right: 8px;
      cursor: pointer;
      font-weight: bold;
      color: white;
      font-size: 16px;
    }
    
    .remove-incentive:hover {
      color: #000;
    }
    
    /* No Selected Message */
    .no-selected {
      font-style: italic;
      color: #888;
    }
    
    /* Save Button */
    .save-button {
      background-color: #FF5758;
      color: white;
      padding: 10px 15px;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      margin-top: 10px;
    }
    
    .save-button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
    
    