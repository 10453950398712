@media (min-width: 769px) {
.hc-input1 {
    display: flex;
    align-items: center;
    max-width: 530px;
    color: white;
    border-radius: 25px; /* Rounded corners */
    padding: 10px;
  }
  .hc-input2 {
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 8px;
    margin-left: 10px;
  }
  .hc-input3 {
    background-color: #FF5758; /* Light blue background */
    color: white;
    padding: 1px 1px;
    border: none;
    border-radius: 5px;
    padding: 9px;
    font-size: 18px;
    cursor: pointer;
    margin-left: 1px; /* Space between input and button */
    font-weight: bold;
    transition: background-color 0.3s;
  }
  .hc-input3:hover {
    background-color: #e94e50; /* Darker shade on hover */
  }
  .hc-input4 {
      line-height: 0.9; /* Note: line-height should usually be greater than 1 */
      background: #394896;
      padding: 8px;
      border-radius: 5px;
      color: white;
      outline: none;
        border: none;
        resize: none;
        align-items: center;
        box-sizing: border-box;
        padding-left: 10px;
        font-size: 22px;
  }
  .hc-input4::placeholder {
    color: white;        /* Set placeholder text color to white */
    opacity: 1;          /* Ensure the color is fully opaque */
  }
  .hc-input5 {
    line-height: 0.9; /* Note: line-height should usually be greater than 1 */
    background: #7e8ee6;
    padding: 8px;
    border-radius: 5px;
    color: white;
    outline: none;
      border: none;
      resize: none;
      align-items: center;
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 22px;
}
.hc-input5::placeholder {
  color: white;        /* Set placeholder text color to white */
  opacity: 1;          /* Ensure the color is fully opaque */
}
}

@media (max-width: 768px) {
    .hc-input1 {
        display: flex;
        color: white;
        border-radius: 5px; /* Rounded corners */
        background-color: #7e8ee6;
      }
      .hc-input2 {
        display: flex;
        align-items: center;
        color: white;
        border-radius: 8px;
        margin-left: 2px;
        padding-left: 1px;
      }
      .hc-input3 {
        background-color: #FF5758; /* Light blue background */
        color: white;
        padding: 9px;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        margin-left: 1px; /* Space between input and button */
        transition: background-color 0.3s;
      }
      .hc-input3:hover {
        background-color: #e94e50; /* Darker shade on hover */
      }
      .hc-input4 {
        line-height: 1.6; /* Note: line-height should usually be greater than 1 */
        background: #394896;
        border-radius: 5px;
        color: white;
        background-color: #394896;
        outline: none;
        border: none;
        resize: none;
        align-items: center;
        padding-left: 10px;
        font-size: 22px;
    }
    .hc-input4::placeholder {
      color: white;        /* Set placeholder text color to white */
      opacity: 1;          /* Ensure the color is fully opaque */
    }
    .hc-input5 {
      line-height: 1.6; /* Note: line-height should usually be greater than 1 */
      background: #7e8ee6;
      border-radius: 5px;
      color: white;
      outline: none;
      border: none;
      resize: none;
      align-items: center;
      padding-left: 10px;
      font-size: 22px;
  }
  .hc-input5::placeholder {
    color: white;        /* Set placeholder text color to white */
    opacity: 1;          /* Ensure the color is fully opaque */
  }
    }