/* ------------------------- */
/* General Styles */
body.precompile-page {
    background-color: #002060;
    color: #ffffff;
    font-family: Arial, sans-serif;
  }
  
  .precompile-container {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    text-align: center;
  }
  
  .precompile-title {
    color: #092f7a;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Error Messages */
  .precompile-error {
    color: #FF5758;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  /* ------------------------- */
  /* Documents Section */
  .documents-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .uploaded-documents-card {
    border: 2px dashed #007bff;
    background-color: #f8faff;
  }
  
  /* Document Titles */
  .documents-title {
    color: #ff4500;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
  }
  
  /* ------------------------- */
  /* Documenti Richiesti Section */
  .documents-blocks {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Individual Document Block */
  .document-block {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Card-like appearance */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .document-block:hover {
    transform: scale(1.02); /* Slight hover effect */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
  
  /* Document Block Header */
  .document-block-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .block-icon {
    font-size: 24px;
    color: #007bff;
    margin-right: 10px;
  }
  
  .document-block h4 {
    margin: 0;
    font-size: 20px;
    color: #002060;
    font-weight: bold;
  }
  
  /* Documents Grid within Blocks */
  .documents-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    text-align: left;
  }
  
  /* Individual Required Document Item */
  .document-item {
    position: relative;
    cursor: pointer;
    background-color: #ffffff;
    color: #002060;
    padding: 12px 15px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #ddd;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    align-items: center;
  }
  
  .document-item:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  }
  
  /* Tooltip for Document Item */
  .tooltip {
    position: absolute;
    bottom: 125%; /* Position above the document item */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 14px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1000;
  }
  
  .document-item:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
  
  .tooltip::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow pointing down */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
  
  /* Add a subtle icon for document items */
  .document-item::before {
    content: "📄"; /* Placeholder icon */
    font-size: 18px;
    margin-right: 8px;
    color: #007bff;
  }
  
  /* ------------------------- */
  /* Documenti Caricati Section */
  .uploaded-documents-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    max-height: 350px;
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  
  .uploaded-document-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease;
  }
  
  .uploaded-document-item:hover {
    background-color: #e9f2ff;
  }
  
  .uploaded-document-link {
    color: #007bff;
    font-size: 16px;
    text-decoration: none;
    flex: 1;
  }
  
  .uploaded-document-link:hover {
    text-decoration: underline;
  }
  
  .delete-icon-button {
    background: none;
    border: none;
    color: #dc3545;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .delete-icon-button:hover {
    color: #a71d2a;
  }
  
  /* Empty List Message */
  .uploaded-documents-empty {
    color: #555;
    font-size: 16px;
    text-align: center;
    padding: 20px;
    background-color: rgba(249, 249, 249, 0.9);
    border-radius: 8px;
  }
  
  /* ------------------------- */
  /* Buttons */
  .upload-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .upload-button {
    background-color: #007bff;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .upload-button:hover {
    background-color: #0056b3;
  }
  
  .next-button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .next-button {
    background-color: #ff8c00;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .next-button:hover {
    background-color: #e07b00;
  }
  
  /* ------------------------- */
  /* Responsive Design */
  @media (max-width: 768px) {
    .documents-grid {
      grid-template-columns: 1fr; /* Single column for small screens */
    }
  
    .uploaded-document-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .delete-icon-button {
      margin-top: 10px; /* Ensure spacing for smaller layouts */
    }
    .documents-card {
      background-color: #ffffff;
      color: #002060;
      padding: 2px;
      border-radius: 10px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  }

  @media (min-width: 769px) {
    .documents-card {
      background-color: #ffffff;
      color: #002060;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  }