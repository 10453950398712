/* Reset some default styles */
body, ul {
    margin: 0;
    padding: 0;
  }
  
  nav {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }
   
  .logo {
    font-size: 24px;
  }
  
  .menu {
    list-style: none;
    display: flex;
  }
  
  .menu li {
    margin-right: 20px;
  }
  
  .menu a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .menu a:hover {
    color: #ff5733;
  }
  
  /* Style the burger button */
  .menu-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    
  }
  
  .bar {
    width: 30px;
    height: 10px;
    background-color: #fff;
    margin: 4px 0;
  }


  .headerStyles {
    background-color: #092f7a;
    color: white;
    text-align: center;
    font-family: "Inter", sans-serif;
    display: flex;
    border: none;
    cursor: pointer;
    padding-left: 5px;
    flex-direction: column; /* Make it stack vertically */
    justify-content: center; /* Center items vertically */
    align-items: left; /* Center items horizontally */
}

.sloganStyles {
  color: #092f7a ;
    font-family: "Inter", sans-serif;
    text-align: left;
    display: flex;
    font-weight: bolder;
    line-height: 1.5;
    padding-left: 20px;
    white-space: nowrap;
}

.sloganStyles2 {
  color: #7f7777 ;
    font-family: "Inter", sans-serif;
    text-align: left;
    display: flex;
    font-weight: bolder;
    line-height: 1.5;
    white-space: nowrap;
    letter-spacing: 0.25em; 
}


/* styles.css */
.sloganStylesH {
  
  /* Center the box horizontally */
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #092f7a ;
  font-weight: bold;
  font-size: 24px;
  /* Optional: Center vertically */
  justify-content: center;
  
  /* Set a max width and allow it to be responsive */
  max-width: 600px;
  width: 90%; /* Adjust as needed */
  
  /* Add padding and background */
  padding: 2px;
  background-color: #ffffff; /* White background */
  
  /* Add shadow and rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  
  /* Ensure content doesn't overflow */
  overflow: hidden;
  
  /* Optional: Add margin to center vertically if needed */
  margin: 10px auto; /* 50px top & bottom, auto left & right */
}
/* Container for the slogan box */
.sloganStyles3 {
  /* Center the box horizontally */
  display: flex;
  font-size: 15px;
  flex-direction: column;
  align-items: center;
  /* Optional: Center vertically */
  justify-content: center;
  
  /* Set a max width and allow it to be responsive */
  max-width: 600px;
  width: 90%; /* Adjust as needed */
  
  /* Add padding and background */
  padding: 20px;
  background-color: #ffffff; /* White background */
  
  /* Add shadow and rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  
  /* Ensure content doesn't overflow */
  overflow: hidden;
  
  /* Optional: Add margin to center vertically if needed */
  margin: 10px auto; /* 50px top & bottom, auto left & right */
}

/* Inner text styling */
.sloganStyles3 .inner-text {
  text-align: left;
  font-family: sans-serif;
  font-size: 24px;
  color: #092f7a;
  
  /* Ensure text wraps within the container */
  word-wrap: break-word;
  overflow-wrap: break-word;
  
  /* Optional: Add margin for spacing */
  margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sloganStyles3 {
    max-width: 90%;
    padding: 15px;
  }
  
  .sloganStyles3 .inner-text {
    font-size: 20px;
  }
}

.captionStyles {
  color: #092f7a; 
  font-family: "Inter", sans-serif;
  text-align: left;
  display: flex;
  padding-left: 20px;
  padding-right: 80px;
  letter-spacing: 0.05em; 
  line-height: 1.5;
  font-weight: bold;
}

.letterSpacing {
  display: inline-block;
  width: 8px;  
}


.navContainer {
  display: flex;
    align-items: center;  
    width: 100%;
}

.navContainer-mob {
  display: flex;
    align-items: center;  
    justify-content: flex-start;
}

.menuToggleContainer {
  display: none;
}

.userLogin {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 5px;
  color: #FF5758;
}
.navMenu {
  display: flex;  
    align-items: center;  
    width: 100%;  
    justify-content:space-around;
    padding-top: 20px;
    padding-bottom: 3px;
}

.navItem {
  cursor: pointer;
}

  
  /* desktop & tablet config */
@media (min-width: 770px) {
  .headerStyles {
    font-size: 50px;
    letter-spacing: 20px;
  }
  .sloganStyles {
    font-size: 58px;
}
.sloganStyles2 {
  font-size: 14px;
  padding-left: 25px;
}
.sloganStyles3 {
  font-size: 20px;
}
.captionStyles {
  font-size: 28px;
  margin-top: 15px;
}
  .menu-font-size {
    font-size: 20px;
  }
  .menuToggleContainer {
    display: none;  /* Ensure it remains hidden on desktop */
}
.navMenu {
    display: flex;  /* Show the nav items directly in the navbar */
    list-style: none;
    margin: 0;
    width: 70%;
}
.navItem {
    margin-right: 20px;  /* Spacing between nav items */
}

  .pluto-pic {
    width: 30px !important; /* Adjust the width for desktop */
    height: 30px !important; /* Adjust the height for desktop */
    position: relative;
    background: #092f7a;
    border-Top: none;
    border-Bottom: none;
    border-Radius: 20px;
    border-Color: white;
    box-Shadow: 0 0 10px rgba(255, 255, 255, 1);
    cursor: pointer;
  }

  .menu.active {
    display: flex;
  }

  .menu-toggle {
    display: flex;
  }
  
  .header-containerw {
    display: flex; /* Use flexbox for horizontal alignment */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space out items */
    padding: 2px 50px; /* Add some padding */
    background-color: #092f7a; /* Background color for the header */
    height: 60px; /* Set a height for the header */
  }
}

/* mobile */
@media (max-width: 768px) {
  .header-containerw {
    display: flex; /* Use flexbox for horizontal alignment */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space out items */
    height: 60px; /* Set a height for the header */
  }
  .menu {
    font-size: 20px;
  }

  .headerStyles {
    font-size: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 10px;
  }
  .sloganStyles {
    font-size: 24px;
    margin-bottom: 5px;
}
.sloganStyles2 {
  font-size: 10px;
  margin-bottom: 5px;
  padding-left: 20px;
}
.sloganStyles3 {
  font-size: 15px;
  margin-bottom: 5px;
}
.captionStyles {
  font-size: 14px;
  margin-top: 10px;
  max-width: 100%;
}
.navContainer {
  align-items: center;  
}
.menuToggleContainer, .headerStyles, .userLogin {
  width: 100%;  
  text-align: center;  
  margin-top: 2px;  
  align-items: center;
  display: flex;
  margin-bottom: 3px;
  justify-content: center;
}

.menuToggleContainer {
  display: flex;  /* Show only on mobile */
}
.navMenu {
  display: none;  /* Show the nav items directly in the navbar */
}

  .menu.active {
    display: flex;
  }

  .menu-toggle {
    display: flex;
  }

  .menu-font-size {
    font-size: 16px;
  }

  .pluto-pic {
    width: 30px;
    height: 30px;
    position: relative;
    background: #092f7a;
    border-Top: none;
    border-Bottom: none;
    border-Radius: 20px;
    border-Color: white;
    box-Shadow: 0 0 10px rgba(255, 255, 255, 1);
    cursor: pointer;
  }
  .brand-logo-fp{
    height: auto;
    margin: 1px 5px 1px 1px;
  }
}

  
  @import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

/* Your existing CSS styles go here */
.text-title {
  font-family: 'Monoton', sans-serif; /* Apply the imported font to your styles */
}

@keyframes flash {
  0%, 20% {
    color: rgb(225, 141, 23); 
  }
  20%, 40% {
    color: rgb(255, 255, 255); 
  }
  40%, 60% {
    color: rgb(225, 141, 23); 
  }
  60%, 80% {
    color: rgb(255, 255, 255); 
  }
}

.cart-icon-container {
  position: relative;
  font-size: 24px; /* Icon size */
  cursor: pointer;
}

.cart-icon-container .fa-shopping-cart {
  color: white; /* Icon color */
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: rgb(225, 141, 23);
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  line-height: normal;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex; /* You might adjust or remove these flex properties */
}

.popup-inner {
  position: fixed;  /* Makes it positioned relative to the viewport */
  top: 2px;        /* Spacing from the top */
  right: 2px;      /* Spacing from the right */
  padding: 20px;
  background: black; /* Changed from black for visibility */
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.5);
  text-align: center;
  width: auto; /* You might want to set a specific width or keep it auto */
  max-width: 300px; /* Optional, if you want to limit the width */
}

/* Dropdown menu styles */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #444;
  padding: 10px 0;
  border-radius: 5px;
  min-width: 150px;
  z-index: 1000;
}

.dropdown-item {
  padding: 8px 20px;
  cursor: pointer;
  color: white;
}

.dropdown-item:hover {
  background-color: #555;
}

.nav-container {
  display: flex;
}

/* Pseudo-element for the arrow */
.navItem.dropdown .navLink::after {
  content: '';
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Rotate the arrow when dropdown is open */
.navItem.dropdown.open .navLink::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.dropdowno {
  display: flex;
  flex-direction: column; /* Stack dropdown items vertically */
  position: absolute; /* Position it absolutely within the navMenu */
  left: 208px; /* Align to the left of the parent */
  top: 100%; /* Position it below the parent item */
  margin-top: 5px; /* Optional space between header and dropdown */
  background-color: white; /* Set a background color for the dropdown */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow for better visibility */
  z-index: 1000; /* Ensure dropdown appears above other elements */
}

.customNavMenu {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  position: relative; /* This allows absolute positioning for the dropdown */
}

.customNavItem {
  cursor: pointer;
  padding: 10px;
  border: 1px solid transparent; /* Make border appear on hover */
}

.customNavItem:hover {
  border: 1px solid #FF5758; /* Change border color on hover */
  background-color: rgba(255, 87, 88, 0.1); /* Light background on hover */
}

.customDropdown {
  display: flex;
  flex-direction: column; /* Stack dropdown items vertically */
  position: absolute; /* Position it absolutely within the navMenu */
  left: 0; /* Align to the left of the parent */
  top: 100%; /* Position it below the parent item */
  margin-top: 5px; /* Optional space between header and dropdown */
  background-color: white; /* Set a background color for the dropdown */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow for better visibility */
  z-index: 1000; /* Ensure dropdown appears above other elements */
  padding: 0; /* Remove padding to avoid indentation */
}

.customDropdown .customNavItem {
  border: none; /* Remove border for dropdown items */
  padding: 10px; /* Adjust padding for dropdown items */
  background-color: white; /* Maintain background color for dropdown items */
}

.customDropdown .customNavItem:hover {
  background-color: rgba(255, 87, 88, 0.1); /* Maintain hover effect */
}

.headerStylesw {
  background: none; /* Remove default button styling */
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor on hover */
  display: flex; /* Ensure the button itself is a flex container */
  align-items: center; /* Center the logo within the button */
}

.brand-logo-fpw {
  height: 40px; /* Adjust logo height as needed */
}

.menuToggleContainerw {
  cursor: pointer; /* Change cursor on hover */
}

/* Additional styles for the user login section */
.userLoginw {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  margin-left: auto; /* Push to the right */
}

.user-iconw {
  margin-right: 10px; /* Space between icon and email */
}

.logout-buttonw {
  margin-left: 10px; /* Space between email and logout button */
}


.logo-container {
  display: flex;
  align-items: center; /* Vertically center items */
  justify-content: flex-start; /* Align items to the left */
  padding: 0; /* Remove any padding if necessary */
  margin-right: auto;
}

.menu-container {
  display: flex;
  justify-content: center; /* Center the menu items */
  margin-left: auto;
  flex-grow: 1; /* Allow the menu to grow and take available space */
}

.user-info {
  display: flex;
  align-items: center;
}

.user-icon {
  margin-right: 2px; /* Space between icon and email */
}

.user-email {
  padding: 0 100px; /* Space between email and logout button */
}


/* Custom class for the DropdownButton */
.custom-dropdown-button {
  background-color: white; /* Desired button color */
  color: #092f7a;            /* Text color */
  border: none;              /* Remove default border */
}

.custom-dropdown-button:hover,
.custom-dropdown-button:focus,
.custom-dropdown-button:active,
.custom-dropdown-button:not(:disabled):not(.disabled).active,
.custom-dropdown-button:not(:disabled):not(.disabled):active,
.custom-dropdown-button.dropdown-toggle::after {
  background-color: white; /* Maintain button color on hover/focus */
  color: #092f7a;
  border: none;
}

/* Custom class for the Dropdown Menu */
.custom-dropdown-menu {
  background-color: white; /* Desired dropdown menu background */
  color: #092f7a;            /* Text color */
  border: none;              /* Remove default border */
}

/* Custom class for Dropdown Items */
.custom-dropdown-menu .dropdown-item {
  color: #092f7a; /* Text color */
}

.custom-dropdown-menu .dropdown-item:hover,
.custom-dropdown-menu .dropdown-item:focus {
  background-color: #051d43; /* Darker shade on hover/focus */
  color: #092f7a;
}

.custom-dropdown-button .btn {
  background-color: white !important; /* Desired button color */
  color: #092f7a !important;            /* Text color */
  border: none !important;              /* Remove default border */
}


.red-letter {
  color: #FF5758;
}

/* Style for blue letters */
.blue-letter {
  color: #092f7a;
}