
  .button-container button {
    display: block;
    margin-bottom: 5px;
    width: 90px;
  }

  .button-style {
    color: white;
    margin: 10px;
    border: 1px solid  rgb(225, 141, 23);
    border-radius: 5px;
    background-color: black;
    align-items: center;
    cursor: pointer;
    padding-right: 5px;
    margin-left: 10px;
  }



.login-container {
    background-color: #43453f;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 340px;
}

.section {
    background-color: #333333;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.section h2 {
    color: #FFFFFF;
    margin-bottom: 10px;
}

.section .button {
    background-color: #FF5758;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
}

.section .button:hover {
    opacity: 0.9;
}

.login-form h2 {
    color: #FFFFFF;
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 15px;
    text-align: left;
}

.input-group label {
    color: #FFFFFF;
    display: block;
    margin-bottom: 5px;
}

.input-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
}

.button-group button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    background-color: #092f7a;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
}

.button-group button:hover {
    opacity: 0.9;
}

.horizontal-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}
