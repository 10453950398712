.event-input-field {
    width: 100%;
    padding-top: 5px; 
    padding-bottom: 5px;
    margin-bottom: 5px;
    z-index: 1;
    text-align: left;
    padding-left: 5px; /* Adjust the padding as needed */
    background-color: white;
    color: rgb(1, 1, 1);
    border: 1px solid rgb(230, 224, 224); /* Add a 1px solid black border */
    border-radius: 4px; 
  } 

  /*desktop*/
  @media (min-width: 1441px) {
  .about-section {
    background-color: #020202;
    padding-left: 35%;
    padding-right: 35%;
  }
}

  /*mobile*/
  @media (max-width: 600px) {
  .about-section {
    background-color: #020202;
    padding-left: 5;
    padding-right: 5%;
  }
  }

  .button-spacing {
    margin-right: 10px; /* Adjust the value to control the amount of space */
  }

  .input-container {
    margin: 0 auto; /* Center the container horizontally */
    text-align: left; /* Align the input fields to the left within the container */
    z-index: 1;

  }

  .input-label {
    display: block; /* Make the label a block-level element */
    margin-bottom: 5px; /* Add spacing between the label and input field */
    color: rgb(8, 8, 8);
    width: 120px;
    margin-right: 10px;
    z-index: 1;
  }

  .side-by-side {
    display: inline-block;
    margin: 1%; /* Add some margin for spacing between divs */
    z-index: 1;
  } 

  .addressForm {
    text-align: left;
    padding-left: 5px; /* Adjust the padding as needed */
    /*background-color: rgb(255, 179, 255);*/
    background-color: white;
    color: rgb(1, 1, 1);
    border: 1px solid rgb(230, 224, 224); /* Add a 1px solid black border */
    border-radius: 4px; 
    margin-top:80%;
    z-index: 2;
  }

    
  .combobox-input {
    width: 48%;
    padding: 0.5rem;
    margin-bottom: 20px; /* Adjust this value as needed */
    border-radius: 4px;
    color: rgb(238, 12, 12);
    z-index: 2;
  }

  .combobox-popover-class {
    background-color: white;
    /* Add other styles as needed, like border, shadow, etc. */
  }

  .place-container {
    position: absolute;
    margin-top: 1%;
    left: 40%;
    transform: translateX(-80%);
    z-index: 2;
    width: 300px;
    background-color: white;
  }

  .pac-container {
    box-shadow: none;
    border: none;
    background-color: white;
   
}

.pac-container.show {
  display: block; /* Show the suggestions */
  opacity: 1; /* Set opacity to fully visible */
  /* Add any other styling you want */
}


/********************************************************************************************/

/* ProcessSteps.css */

/* Wrapper for the entire component */
.process-wrapper {
  padding: 20px;
  background-color: #f5f5f5; /* Optional: Light background to contrast boxes */
}

/* Title styling */
.process-title {
  text-align: center;
  font-size: 2rem;
  color: #092f7a;
  margin-bottom: 40px;
  margin-top: 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

/* Container for the process boxes */
.process-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Individual process box */
.process-box {
  background-color: #092f7a; /* Dodger Blue */
  color: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  flex: 1 1 calc(33.333% - 40px); /* Three boxes per row with gap */
  max-width: calc(33.333% - 40px);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Hover effect for boxes */
.process-box:hover {
  background-color: #FF5758; /* Darker Blue */
  transform: translateY(-5px);
}

/* Numbering circle */
.process-number {
  position: absolute;
  top: -15px;
  left: -15px;
  background-color: #FFFFFF;
  color: #092f7a;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Title inside the box */
.process-description-title {
  font-size: 1.8rem;
  margin-bottom: 10px;
  font-weight: bold;
  width: 70%;
}

/* Description text */
.process-description {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-top: 5px;
  width: 70%;
}

/* Responsive Design */

/* Tablet view (between 768px and 1024px) */
@media (max-width: 1024px) and (min-width: 768px) {
  .process-box {
    flex: 1 1 calc(45% - 40px); /* Two boxes per row */
    max-width: calc(45% - 40px);
  }
}

/* Mobile view (below 768px) */
@media (max-width: 767px) {
  .process-box {
    flex: 1 1 100%; /* One box per row */
    max-width: 100%;
  }

  .process-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
}

.process-box {
  padding: 20px;
  border-radius: 15px;
  background-color: #003366; /* A soft blue background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.process-box img {
  width: 100px; /* Smaller size for a balanced look */
  height: auto;
  border-radius: 50%; /* Make it a perfect circle */
  margin-bottom: 10px;
  background-color: #f5f7f9; /* A soft blue background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

